<template>
  <div ref="playContainer" class="flex flex-col h-screen max-h-screen bg-white">

    <!-- status bar at the top -->
    <div :style="currentQuestion ? 'grid-template-columns: 1fr 1fr 1fr 1fr' : 'grid-template-columns: 1fr'"
         class="p-4 grid gap-4" id="infobar">

      <!-- prevent quiz logo -->
      <div v-if="currentQuestion" class="h-full flex justify-center items-center">
        <img v-if="accountLogoMediaObject" class="w-auto max-h-16" :src="accountLogoMediaObject.url">
      </div>

      <!-- quiz is starting soon or is over-->
      <div v-if="!currentQuestion" class="custom-card h3 text-center m-0">
        {{ quiz.currentQuestion < 0 ? 'Das Quiz startet gleich...' : 'Danke fürs Mitspielen!' }}
      </div>

      <!-- current question -->
      <div v-else class="custom-card-highlight h3 m-0 text-center">
        Frage {{ currentQuestionWithoutSlides + 1 }} von {{ questionsWithoutSlides.length }}
      </div>

      <!-- current state -->
      <!--div v-if="currentQuestion" class="custom-card h3 flex flex-row items-center justify-around m-0">
        <font-awesome-icon :icon="['fas', 'question-circle']" class="" />
        <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-gray-300" />
        <font-awesome-icon :class="questionState < 2 && 'text-gray-300'" :icon="['fas', 'check-square']"
                           class="z-20 inline" />
        <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-gray-300" />
        <font-awesome-icon :class="questionState < 3 && 'text-gray-300'" :icon="['fas', 'clock']" class="z-20 inline" />
        <font-awesome-icon :icon="['fas', 'chevron-right']" class="text-gray-300" />
        <font-awesome-icon :class="questionState < 4 && 'text-gray-300'" :icon="['fas', 'poll-h']"
                           class="z-20 inline" />
      </div-->

      <!-- progress bar -->
      <div v-if="currentQuestion" class="custom-card relative overflow-hidden h3" style="border-color: transparent;">
        <font-awesome-icon :icon="['fas', 'user-check']" class="mr-6 z-20 relative inline"/>
        <span class="z-20 relative">{{ Math.round(percentVoted * 100) }}% Abgestimmt</span>
        <div :style="{width: (percentVoted * 100) + '%'}"
             class="absolute top-0 left-0 h-full bg-gray-200 z-10 transition-all duration-200"/>
      </div>

      <!-- timer -->
      <div :class="timer.start && (timer.currentValue < 10 ? 'red-bg' : 'orange-bg')" v-if="currentQuestion"
           class="custom-card h3 m-0 text-white">
        <font-awesome-icon :icon="['fas', 'clock']" class="mr-6 inline"/>
        <span v-if="timer.start">{{ timer.currentValue }} s</span>
        <span v-else>-</span>
      </div>
    </div>

    <!-- question content and options -->
    <div v-if="currentQuestion"
         :style="quizContentContainerInlineStyles"
         class="flex-grow p-4 pt-0 grid gap-4">

      <!-- question content -->
      <div class="custom-card flex flex-col relative overflow-hidden">
        <p class="h2 relative z-20">{{ currentQuestion.title }}</p>

        <p class="absolute z-10 bottom-0 right-0 transform rotate-12 mr-16 -mb-32 font-extrabold text-gray-100"
           style="font-size: 600px;" v-if="currentQuestion.type !== QUESTION_TYPES.SLIDE">?</p>

        <img v-if="questionMediaObject && questionMediaObject.meta.contentType.includes('image')"
             :alt="questionMediaObject.alt" :src="questionMediaObject.url" class="w-auto h-16 flex-grow relative z-20"
             style="width: fit-content">

        <video v-if="questionMediaObject && questionMediaObject.meta.contentType.includes('video')" ref="video"
               :src="questionMediaObject.url" class="h-16 flex-grow relative z-20" controls
               style="aspect-ratio: 16/9; width: fit-content"/>

        <iframe v-if="questionYoutubeId"
                id="yt-player"
                :src="`https://www.youtube-nocookie.com/embed/${questionYoutubeId}?enablejsapi=1`"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen class="h-16 flex-grow relative z-20"
                style="aspect-ratio: 16/9; width: fit-content;"></iframe>

      </div>

      <div v-if="currentQuestion.options.length > 0 && currentQuestion.type !== QUESTION_TYPES.SLIDE"
           :class="currentQuestion.options.length <= 4 ? 'grid-cols-1' : 'grid-cols-2 grid-flow-col grid-rows-4'"
           class="grid gap-4">
        <!-- options -->
        <div v-for="(option, index) in currentQuestion.options"
             class="custom-card flex flex-row items-center justify-items-start z-20 relative overflow-hidden">

          <!-- number -->
          <span v-if="questionState >= 2"
                class="w-10 h-10 bg-gray-800 h3 text-white font-bold flex-shrink-0 bg-white rounded-full inline-flex justify-center items-center mr-4 relative z-20">
          {{ index + 1 }}
          </span>

          <!-- content -->
          <span v-if="questionState >= 2" class="z-20 relative h4">
          {{
              option + (questionState === 4 && votesForCurrentQuestion.length ? ': ' + Math.round(percentVotedFor(option) * 100) + '%' : '')
            }}
          </span>

          <!-- percent of votes in background -->
          <div v-if="questionState === 4"
               :class="evaluateOption(currentQuestion, index) && questionState >= 4 ? 'bg-green' : 'bg-red-300'"
               :style="{width: (percentVotedFor(option) * 100) + '%'}"
               class="absolute top-0 left-0 w-full h-full z-10 appear-to-right rounded-l-xl"/>

          <!-- green outer marking -->
          <div
              :class="evaluateOption(currentQuestion, index) && currentQuestion.type === QUESTION_TYPES.KNOWLEDGE && questionState >= 4 && 'green-inner-shadow'"
              class="absolute top-0 left-0 w-full h-full z-20 rounded-xl"/>
        </div>
      </div>

    </div>

    <div v-else class="flex-grow flex flex-col justify-center items-center">
      <img class="h-96" style="max-width: 75%" v-if="organizationLogoMediaObject"
           :src="organizationLogoMediaObject.url">
    </div>

    <!-- status bar at the bottom -->
    <div class="flex flex-row justify-center items-center p-4 pt-0 gap-4">

      <!-- open and close fullscreen -->
      <button class="button flex-shrink-0" @click="toggleFullscreen">
        <font-awesome-icon v-if="!fullscreen" :icon="['fas', 'expand']" size="lg"/>
        <font-awesome-icon v-else :icon="['fas', 'compress']" size="lg"/>
      </button>

      <!-- go to dashboard button -->
      <button class="button flex-shrink-0" style="color: rgb(59, 67, 81) !important;"
              @click="(fullscreen && toggleFullscreen()); $router.push('/dashboard')">
        <font-awesome-icon :icon="['fas', 'home']" size="lg"/>
      </button>

      <!-- arrow left -->
      <button class="button flex-shrink-0" @click="goSteps(-1)">
        <font-awesome-icon :icon="['fas', 'chevron-left']" size="lg"/>
      </button>

      <!-- arrow right -->
      <button class="button flex-shrink-0" @click="goSteps()">
        <font-awesome-icon :icon="['fas', 'chevron-right']" size="lg"/>
      </button>

      <!-- show qr code -->
      <button class="button flex-shrink-0" @click="qrOpen = true;">
        <font-awesome-icon :icon="['fas', 'qrcode']" size="lg"/>
      </button>

      <!-- show leaderboard -->
      <button class="button flex-shrink-0" @click="leaderboardOpen = true;">
        <font-awesome-icon :icon="['fas', 'star']" size="lg"/>
      </button>

    </div>

    <!-- close overlay buttton -->
    <button v-if="qrOpen || leaderboardOpen" class="fixed top-0 right-0 z-60 p-4 rounded-3xl"
            @click="qrOpen = false; leaderboardOpen = false;">
      <font-awesome-icon :icon="['fas', 'times']" class="text-white z-60" size="2x"/>
    </button>

    <!-- qr-code and members -->
    <div v-show="qrOpen" class="popup-background">

      <!-- qr-code -->
      <div class="custom-card">
        <p class="h1 text-center">Am Quiz teilnehmen</p>
        <vue-qr :size="900" :text="url(true)" class="max-h-23 mx-auto" colorDark="rgb(59, 67, 81)"
                style="display: block !important;"/>
        <p class="h2 mt-4 mb-0 text-bold">{{ url(false) }}</p>
      </div>

      <!-- members -->
      <div class="custom-card max-h-full flex flex-col">
        <p class="h2">Teilnehmer</p>
        <ul class="list-decimal list-inside">
          <li v-for="player in $store.state.play.players">
            {{ player.id }}
          </li>
        </ul>

        <!-- no members yet -->
        <p v-if="!$store.state.play.players.length">
          Noch keine Teilnehmer registriert. Wo bleibt ihr? </p>
      </div>

    </div>

    <!-- leaderboard -->
    <div v-show="leaderboardOpen" class="popup-background max-h-screen p-4">

      <div class="custom-card w-1/2 max-h-full flex flex-col">
        <div class="h1">
          Bestenliste
          <button class="btn float-right mt-3" @click="shareLeaderboard">
            <span class="inline-block mx-4">Mit Spieler:innen teilen</span>
          </button>
        </div>
        <leaderboard :leaderboard="leaderboard"/>
      </div>

    </div>

  </div>
</template>
<script>
import VueQr from "vue-qr";
import { quizCollection } from "../plugins/firebase";
import hotkeys from 'hotkeys-js';
import { ANSWER_TYPES, getPrimaryOrganization, QUESTION_TYPES } from "../constants/questions"
import { evaluateOption, evaluateVote, filterVoteForOption, leaderboard } from "../store/play";
import Leaderboard from "../components/Leaderboard";
import { addUniqueZoomMessage, hasVerticalScroll, infoBarTooLarge } from "@/plugins/check-zoom";
import debounce from "lodash.debounce";
import store from "@/store";

export default {
  data() {
    return {
      QUESTION_TYPES,
      qrOpen: false,
      leaderboardOpen: false,
      fullscreen: false,
      questionState: 0, // 0 = showing content, 1 = starting videos, 2 = showing options, 3 = timer & voting running, 4 = showing results
      timer: {
        start: 0,
        currentValue: 0,
        duration: 0,
        interval: null,
        audio: null,
      }
    }
  },
  created() {
    this.$store.dispatch("loadPlayers", this.quiz);
    this.$store.dispatch("loadVotes", this.quiz);

    // set current question
    if (this.quiz.currentQuestion == null)
      quizCollection.doc(this.quiz.id).update({
        currentQuestion: -1
      });

    // show qr code in the beginning
    if (!this.currentQuestion)
      this.qrOpen = true;

    // listen for arrow hotkeys
    hotkeys('left', () => this.goSteps(-1));
    hotkeys('PageUp', () => this.goSteps(-1));
    hotkeys('right', () => this.goSteps());
    hotkeys('PageDown', () => this.goSteps());
  },
  mounted() {
    function checkZoom() {
      console.log(document.getElementById("infobar"));
      if (hasVerticalScroll() || infoBarTooLarge(document.getElementById("infobar")))
        addUniqueZoomMessage();
    }

    checkZoom();
    window.addEventListener('resize', debounce(checkZoom, 500));
  },
  beforeDestroy() {
    if (this.timer.audio)
      this.timer.audio.pause();
    hotkeys.unbind();
  },
  computed: {
    quiz() {
      return this.$store.state.quiz.quizzes.filter(quiz => quiz.id === this.$route.params.id)[0]
    },
    isCurrentQuestionSlide() {
      return this.currentQuestion.options.length === 0 || this.currentQuestion.type === QUESTION_TYPES.SLIDE;
    },
    quizContentContainerInlineStyles() {
      if (this.isCurrentQuestionSlide)
        return { 'grid-template-columns': '100fr' };
      else if (this.currentQuestion.options.length <= 4)
        return { 'grid-template-columns': '66fr 33fr' };
      else
        return { 'grid-template-columns': '50fr 50fr' };
    },
    questionsWithoutSlides() {
      return this.quiz.questions.filter(question => question.options.length !== 0 && question.type !== QUESTION_TYPES.SLIDE);
    },
    currentQuestionWithoutSlides() {
      let currentIndex = this.quiz.currentQuestion;
      while (currentIndex >= 0) {
        if (this.quiz.questions[currentIndex].type !== QUESTION_TYPES.SLIDE) {
          return this.questionsWithoutSlides.indexOf(this.quiz.questions[currentIndex]);
        }
        currentIndex--;
      }
      return -1;
    },
    currentQuestion() {
      if (this.quiz.currentQuestion < 0)
        return null;
      return this.quiz.questions[this.quiz.currentQuestion];
    },
    questionYoutubeId() {
      try {
        return new URL(this.currentQuestion.media).searchParams.get("v");
      } catch (e) {
        return null;
      }
    },
    questionMediaObject() {
      return this.$store.state.media.mediaFiles.filter(media => media.ref.fullPath === this.currentQuestion.media)[0];
    },
    organizationLogoMediaObject() {
      const organizationLogo = getPrimaryOrganization(this).logo;
      return this.$store.state.media.mediaFiles.filter(media => media.ref.fullPath === organizationLogo)[0];
    },
    accountLogoMediaObject() {
      const accountLogo = this.$store.state.settings.logo;
      return this.$store.state.media.mediaFiles.filter(media => media.ref.fullPath === accountLogo)[0];
    },
    votesForCurrentQuestion() {
      return this.$store.state.play.votes.filter(vote => vote.question === this.quiz.currentQuestion);
    },
    percentVoted() {
      if (this.$store.state.play.players.length === 0)
        return 0;
      return this.votesForCurrentQuestion.length / this.$store.state.play.players.length;
    },
    timerSoundMediaObject() {
      const sound = (this.currentQuestion ? this.currentQuestion.timerSound : false) || this.$store.state.settings.timerSound;
      return this.$store.state.media.mediaFiles.find(m => m.ref.fullPath.includes(sound));
    },
    leaderboard,
    status() {
      switch (this.questionState) {
        case 0:
          return "Frage durchlesen";
        case 1:
          return "Frage durchlesen";
        case 2:
          return "Antwortoptionen";
        case 3:
          return "Abstimmen";
        case 4:
          return "Ergebnisse";
      }
    }
  },
  watch: {
    percentVoted() {
      if (this.percentVoted === 1 && this.currentQuestion.answerType === ANSWER_TYPES.SINGLE_CHOICE) {
        quizCollection.doc(this.quiz.id).update({
          votingEnabled: false
        });
        this.timer.start = 0;
        if (this.timer.audio)
          this.timer.audio.pause();
        clearInterval(this.timer.interval);
      }
    },
    timerSoundMediaObject: {
      handler() {
        if (this.timer.audio && this.timer.audio.src === this.timerSoundMediaObject.url)
          return;

        this.timer.audio = new Audio(this.timerSoundMediaObject.url);
        this.timer.audio.loop = true;
        this.timer.audio.pause();
      }, immediate: true
    }
  },
  methods: {
    evaluateOption,
    evaluateVote,
    percentVotedFor(option) {
      return this.votesForCurrentQuestion.filter(v => filterVoteForOption(this.currentQuestion, v, option)).length / this.votesForCurrentQuestion.length;
    },
    pauseAudioAndVideo() {
      if (this.$refs.video)
        this.$refs.video.pause();
      if (this.timer.audio)
        this.timer.audio.pause();
      if (document.getElementById("yt-player"))
        document.getElementById("yt-player").contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    },
    url(includeHttp) {
      return `${includeHttp ? 'https://' : ''}${window.location.host}/j/${this.$route.params.id}`;
    },
    shareLeaderboard() {
      return quizCollection.doc(this.quiz.id).update({
        leaderboard: this.leaderboard
      });
    },
    toggleFullscreen() {
      document.onfullscreenchange = () => this.fullscreen = !this.fullscreen;

      if (this.fullscreen)
        document.exitFullscreen();
      else
        document.body.requestFullscreen();
    },
    moveToQuestion(index) {
      quizCollection.doc(this.quiz.id).update({
        currentQuestion: index
      });
      this.questionState = 0;
    },
    moveToNextQuestion(steps = 1) {
      this.moveToQuestion((this.quiz.currentQuestion + steps + 1) % (this.quiz.questions.length + 2) - 1);
    },
    startTimer() {
      if (this.currentQuestion.timerDuration === 0)
        return;

      this.timer.start = Date.now();
      this.timer.duration = this.currentQuestion.timerDuration || this.$store.state.settings.timerDuration;
      this.timer.currentValue = this.timer.duration;
      this.timer.audio.play();
      this.timer.interval = setInterval(() => {
        this.timer.currentValue--;

        // timer ended
        if (this.timer.currentValue <= 0) {
          clearInterval(this.timer.interval);
          this.timer.audio.pause();
          this.updateQuiz({
            votingEnabled: false,
          });
        }
      }, 1000 /* = 1 second */);
    },
    updateQuiz(update) {
      quizCollection.doc(this.quiz.id).update(update);
    },
    goSteps(offset = 1) {

      this.pauseAudioAndVideo();

      if (offset < 0) {
        this.questionState = 0;
        this.moveToNextQuestion(-1);
      }

      this.questionState += offset;

      // stop timer if timer is running
      if (this.timer.start) {
        this.timer.start = 0;
        clearInterval(this.timer.interval);
      }

      // disable voting of if it was enabled
      if (this.quiz.votingEnabled)
        this.updateQuiz({
          votingEnabled: false,
        });

      if (this.currentQuestion == null)
        return this.moveToNextQuestion(1);

      switch (this.questionState) {

          // go previous to previous question
        case -1:
          this.moveToNextQuestion(-1);
          break;

          // show video if included, else go next
        case 1:
          if (this.$refs.video)
            this.$refs.video.play();
          else if (this.questionYoutubeId)
            document.getElementById("yt-player").contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
          else if (this.isCurrentQuestionSlide)
            this.goSteps(4);
          else this.goSteps(1)
          break;

        case 2:
          if (this.isCurrentQuestionSlide && (this.$refs.video || this.questionYoutubeId))
            this.goSteps(2);
          else if (this.isCurrentQuestionSlide)
            this.goSteps(3);
          break;

          // start timer
        case 3:
          this.updateQuiz({
            votingEnabled: true,
          });

          this.startTimer();

          break;

          // go to next question
        case 5:
          this.moveToNextQuestion();
          break;
      }

      // before or after the quiz, go to the first or last question
      if (false && !this.currentQuestion)
        this.moveToQuestion(offset < 0 ? this.quiz.questions.length - 1 : 0)
    },
  },
  components: { Leaderboard, VueQr }
}
</script>
<style lang="scss" scoped>
.custom-card {
  @apply bg-white p-6 rounded-xl shadow-xl border-gray-100 border;
}

.custom-card-highlight {
  @apply p-6 rounded-xl shadow-xl border-gray-100 border bg-gray-800 text-white;
}

.popup-background {
  @apply fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-60 flex justify-center items-center gap-4 p-4 z-50;
}

.button {
  @apply bg-gray-200 shadow-2xl border-2 border-gray-100 rounded-full w-12 h-12 flex justify-center items-center hover:border-gray-500 active:bg-gray-300;
}

$default-card-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

.orange-bg {
  background-color: orange;
  border-color: orange;
}

.red-bg {
  background-color: red;
  border-color: red;
}

.green-inner-shadow {
  box-shadow: inset 0 0 0 6px #b5f3b5, $default-card-shadow;
  border-color: lightgreen;
}

.appear-to-right {
  animation: move linear .2s;
}

.bg-green {
  background-color: #b5f3b5;
}

@keyframes move {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.max-h-23 {
  max-height: 30vh;
}
</style>