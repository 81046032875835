<template>
  <PopupContainer>
    <div class="card max-w-lg">
      <div class="card-header">
        <p class="card-title h4">{{ question }}</p>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-2" @click="$emit('success')">{{ confirm }}</button>
        <button class="btn" @click="$emit('failure')">Abbrechen</button>
      </div>
    </div>
  </PopupContainer>
</template>
<script>
import PopupContainer from "../gui-components/PopupContainer";

export default {
  components: { PopupContainer },
  props: {
    question: String,
    confirm: String,
  }
}

export function deleteQuestion(type, female = false) {
  return `Möchtest du ${female ? 'die' : 'das'} ${type} löschen?`;
}
</script>