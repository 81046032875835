<template>
  <div class="fixed right-0 bottom-0 w-1/3 flex flex-col justify-end p-4 z-40">
    <div class="grid grid-cols-1 gap-2">
      <div v-for="message in $store.state.messages" :class="toastClass(message)" class="toast">
        <button class="btn btn-clear float-right" @click="removeMessage(message)"></button>
        <p>{{ message.text }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { MESSAGE_TYPES } from "../../store";

export default {
  methods: {
    toastClass(message) {
      switch (message.type) {
        case MESSAGE_TYPES.ERROR:
          return "toast-error";
        case MESSAGE_TYPES.SUCCESS:
          return "toast-success";
        default:
          return "";
      }
    },
    removeMessage(message) {
      this.$store.commit("removeMessage", message);
    }
  },
}
</script>