<template>
  <div class="flex-grow overflow-y-auto">
    <table class="table">
      <thead>
      <tr>
        <th>Rang</th>
        <th>Name</th>
        <th>Punkte</th>
        <th>Zeitbonus</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(entry, rang) in leaderboard">
        <td>{{ rang + 1 }}</td>
        <td :class="rang === 0 ? 'font-bold text-blue-800' : ''">{{ entry.name }}</td>
        <td>{{ Math.round((entry.points + Number.EPSILON) * 100) / 100 }}</td>
        <td>{{ entry.time }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    leaderboard: {
      type: Array,
      required: true
    }
  }
}
</script>