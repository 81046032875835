<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title h2">{{ quiz.name }}</div>
      <div class="card-subtitle text-gray">Erstellt: {{ dayjs(quiz.created).format("dddd, DD. MMMM YYYY HH:mm") }}</div>
    </div>
    <div class="card-body">
      <div v-if="!quiz.template && quiz.location" class="card-subtitle font-bold">Ort: {{ quiz.location }}</div>
      <div v-if="!quiz.template && quiz.stufe" class="card-subtitle font-bold">Stufe: {{ quiz.stufe }}</div>
      {{ quiz.description }}
    </div>
    <div class="card-footer">
      <div v-if="!quiz.deleted" class="btn-group">
        <button v-if="quiz.template" class="btn btn-primary" @click="startQuiz">Durchführen</button>
        <button v-if="!quiz.template && !quiz.publicTemplate" :disabled="!quiz.location" class="btn btn-primary"
                @click="$router.push(`/p/${quiz.id}`)">
          Präsentationsmodus öffnen
        </button>
        <router-link v-if="!quiz.publicTemplate" :to="`/e/${quiz.id}`" class="btn">{{ quiz.template ? "Bearbeiten" : "Ansehen" }}</router-link>
        <button v-if="quiz.template" class="btn" @click="duplicateQuiz">Duplizieren</button>
        <button v-if="quiz.publicTemplate" class="btn" @click="duplicateQuiz">Eigene Kopie erstellen</button>
        <button v-if="!quiz.publicTemplate" class="btn" @click="deleteQuiz">Papierkorb</button>
      </div>
      <button v-else class="btn btn-primary" @click="restoreQuiz">Wiederherstellen</button>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import "dayjs/locale/de";
import { quizCollection } from "../../plugins/firebase";
import { logDBError } from "../../store";
import { injectComponentWithReturn } from "../../main";
import ConfirmPopup from "../popups/ConfirmPopup";
import { getPrimaryOrganization } from "@/constants/questions";

dayjs.locale("de");

export default {
  data() {
    return {
      dayjs
    }
  },
  props: {
    quiz: {
      type: Object,
      required: true
    }
  },
  methods: {
    duplicateQuiz() {
      quizCollection.add({
        name: this.quiz.name + " (Kopie)",
        creator: this.$store.state.user.uid,
        description: this.quiz.description,
        created: Date.now(),
        questions: this.quiz.questions,
        template: true,
        basedOnId: this.quiz.id,
        organization: getPrimaryOrganization(this).id
      }).catch(logDBError(this.$store, "Duplizieren fehlgeschlagen"));

      // scroll to top
      window.scrollTo(0, 0);
    },
    restoreQuiz() {
      quizCollection.doc(this.quiz.id).update({
        deleted: false
      }).catch(logDBError(this.$store, "Wiederherstellen fehlgeschlagen"));
    },
    async startQuiz() {

      await injectComponentWithReturn(ConfirmPopup, {
        question: "Wollen Sie das Quiz durchführen?",
        confirm: "Durchführen"
      });

      const doc = await quizCollection.add({
        name: this.quiz.name,
        description: this.quiz.description,
        created: Date.now(),
        questions: this.quiz.questions,
        template: false,
        location: "",
        creator: this.$store.state.user.uid,
        organization: getPrimaryOrganization(this).id
      });

      this.$router.push(`/e/${doc.id}`);
    },
    async deleteQuiz() {
      await injectComponentWithReturn(ConfirmPopup, {
        question: "Wollen Sie das Quiz wirklich löschen?",
        confirm: "Löschen"
      });

      const name = this.quiz.template ? "Quizvorlage" : "Quiz";
      quizCollection.doc(this.quiz.id).update({ deleted: true }).then(() => this.$store.commit("addMessage", `${name} erfolgreich gelöscht`))
          .catch(logDBError(this.$store, `${name} konnte nicht gelöscht werden`));
    }
  }
}
</script>