import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
import EditQuiz from "../views/EditQuiz";
import Play from "../views/Play";
import Join from "../views/Join";
import EditQuestion from "../views/EditQuestion";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: "/e/:id",
    name: "Edit Quiz",
    component: EditQuiz
  },
  {
    path: "/e/:quizid/:question",
    name: "edit-question",
    component: EditQuestion
  },
  {
    path: "/o/:organizationid/:question",
    name: "edit-question-library",
    component: EditQuestion
  },
  {
    path: "/p/:id",
    name: "Play Quiz",
    component: Play
  },
  {
    path: '/j/:id',
    name: "Join Quiz",
    component: Join
  },
  {
    path: '/evaluation/:id',
    name: "Evaluation",
    component: () => import("../views/Evaluation")
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router