<template>
  <div class="w-screen max-w-sm mx-auto flex-col items-center justify-center p-4">

    <!-- spinner if name has not been given yet -->
    <Spinner v-if="!name"/>


    <!-- actual content -->
    <div v-else class="h5 text-center m-0">

      <!-- organization logo -->
      <img v-if="organization" class="px-8 mb-4 w-full" :src="organization.logoUrl">

      <!-- quiz title -->
      <p v-if="content">Willkommen beim Quiz <br>
        <span class="text-bold"> {{ quiz.name }}</span>
      </p>

      <!-- your name -->
      <p :class="content && 'mt-4'" class="block">Dein Name ist <br v-if="content">
        <span class="font-bold">{{ name }}</span>
      </p>

      <!-- multiple choice hint -->
      <span v-if="activeQuestion && activeQuestion.answerType === ANSWER_TYPES.MULTIPLE_CHOICE"
            class="inline-block bg-yellow-300 px-4 py-1 rounded-full mx-auto mb-4 -mt-2 text-xs">Alle Richtigen auswählen</span>

      <!-- creator logo -->
      <img v-if="content && creator" class="px-8 mt-12 max-h-24 mx-auto" :src="creator.logoUrl">

      <!-- answer option -->
      <div v-if="activeQuestion" class="pt-0 grid grid-cols-1 gap-4">
        <button v-for="(option, index) in activeQuestion.options"
                :class="votedForOption(option) && 'bg-blue-200 border-blue-200'"
                class="bg-white p-6 rounded-xl shadow-xl border-gray-100 border flex flex-row justify-between items-center text-center m-0 p-4 select-none"
                @click="vote(option)">

          <!-- number circle -->
          <span
              class="w-10 h-10 bg-gray-800 text-white font-bold text-sm flex-shrink-0 bg-white rounded-full inline-flex justify-center items-center mr-4 relative z-20">
          {{ votedForOption(option) ? "✔️" : index + 1 }}</span>

          <span class="flex-grow h5 text-left">{{ option }}</span>
        </button>
      </div>

      <!-- leaderboard -->
      <div v-if="quiz.leaderboard">
        <p class="h5 text-center">Ergebnisse vom Quiz <br>
          <span class="text-bold"> {{ quiz.name }}</span>
        </p>

        <leaderboard :leaderboard="quiz.leaderboard" class="px-2"/>
      </div>

      <!-- imprint -->
      <p v-if="organization" class="mt-6 text-sm">
        <a :href="organization.imprint" target="_blank">Impressum</a> <br>
        <a :href="organization.privacyPolicy" target="_blank">Datenschutzerklärung</a>
      </p>

    </div>

  </div>
</template>
<script>
import { auth, db, quizCollection, storage } from "../plugins/firebase";
import Spinner from "../components/Spinner";
import firebase from "firebase/app";
import Leaderboard from "../components/Leaderboard";
import { usernames } from "@/constants/usernames";
import { ANSWER_TYPES } from "../constants/questions";

export default {
  components: { Leaderboard, Spinner },
  data() {
    return {
      quizid: this.$route.params.id,
      quiz: null,
      creator: null,
      organization: null,
      name: null,
      votes: {},
      ANSWER_TYPES
    }
  },
  async created() {
    // first create anonymous account
    await auth.signInAnonymously();

    // get creator and organization id
    const { creator: creatorID, organization: organizationID } = (await quizCollection.doc(this.quizid).get()).data();

    // load orgnaization and creator CI details
    const [creator, organization] = (await Promise.all([
      db.collection("settings").doc(creatorID).get(),
      db.collection("organizations").doc(organizationID).get()
    ])).map(doc => doc.data());

    creator.logoUrl = await storage.ref(creator.logo).getDownloadURL();
    organization.logoUrl = await storage.ref(organization.logo).getDownloadURL();

    this.creator = creator;
    this.organization = organization;

    // subscribe to quiz changes
    quizCollection.doc(this.quizid).onSnapshot(doc => {
      this.quiz = {
        id: doc.id,
        ...doc.data(),
      };
    });

    // subscribe to vote changes and store them in votes object
    quizCollection.doc(this.quizid).collection("votes").where("userid", "==", auth.currentUser.uid).onSnapshot(snapshot => {
      this.votes = {};
      snapshot.forEach(doc => {
        this.votes[doc.data().question] = doc.data();
      });
    });

    // retrieve name
    await getRandomName(this.quizid).then(name => {
      this.name = name;
    });
  },
  methods: {
    // vote for an option of the current question
    vote(option) {
      if (this.activeQuestion.answerType === ANSWER_TYPES.MULTIPLE_CHOICE) {
        // calculate new options array
        const currentlyVoted = this.votes[this.activeQuestionID] && this.votes[this.activeQuestionID].options.includes(option);
        const currentOptions = this.votes[this.activeQuestionID] ? this.votes[this.activeQuestionID].options : [];
        const newOptions = currentlyVoted ? currentOptions.filter(o => o !== option) : [...currentOptions, option];

        quizCollection.doc(this.quizid).collection("votes").doc(`${auth.currentUser.uid}-${this.activeQuestionID}`).set({
          name: this.name,
          userid: auth.currentUser.uid,
          question: this.activeQuestionID,
          options: newOptions,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        });
      } else {
        quizCollection.doc(this.quizid).collection("votes").doc(`${auth.currentUser.uid}-${this.activeQuestionID}`).set({
          name: this.name,
          userid: auth.currentUser.uid,
          question: this.activeQuestionID,
          option,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
    },
    votedForOption(option) {
      return this.votes[this.activeQuestionID] && (this.votes[this.activeQuestionID].option === option || (this.votes[this.activeQuestionID].options && this.votes[this.activeQuestionID].options.includes(option)));
    }
  },
  computed: {
    ANSWER_TYPES() {
      return ANSWER_TYPES
    },
    activeQuestionID() {
      if (!this.quiz || this.quiz.currentQuestion == null || this.quiz.currentQuestion < 0 || !this.quiz.votingEnabled)
        return null;
      else
        return this.quiz.currentQuestion;
    },
    activeQuestion() {
      if (this.activeQuestionID == null)
        return null;
      else
        return { ...this.quiz.questions[this.activeQuestionID] };
    },
    content() {
      return !this.activeQuestion && !this.quiz.leaderboard;
    }
  }
}

async function getRandomName(quizid) {
  // check for existing name
  const query = await quizCollection.doc(quizid).collection("players").where("userid", "==", auth.currentUser.uid).get();

  if (query.docs.length > 0)
    return query.docs[0].id;

  return await db.runTransaction(async t => {

    for (let i = 0; i < 5; i++) {
      const tryName = usernames.random();
      const query = await t.get(quizCollection.doc(quizid).collection("players").doc(tryName));

      if (!query.exists) {

        // create document
        await t.set(quizCollection.doc(quizid).collection("players").doc(tryName), {
          userid: auth.currentUser.uid,
          score: 0,
          created: Date.now()
        });

        return tryName;
      }
    }

    alert("Alle Namen sind bereits vergeben, du kannst leider nicht mitspielen. ");

  })
}
</script>