<template>
  <div v-if="question">

    <info-and-auth-bar
        :back-link="`/e/${quiz.id}`"
        :back-name="isOrganization ? null : 'Quiz'"
        :title="`Bearbeitung ${isOrganization ? 'Fragenbibliothek' : 'Quizvorlage'} „${quiz.name}“ Frage #${parseInt($route.params.question) + 1 }`"/>

    <div class="grid" style="grid-template-columns: 4rem 1fr 4rem;">

      <!-- left button -->
      <div :class="{ 'invisible': isOrganization }" class="flex justify-center items-center ml-4"
           style="height: calc(100vh - 2.26rem);">
        <button v-show="hasLeftQuestion" class="button" @click="goToQuestion(false)">
          <font-awesome-icon icon="chevron-left" size="lg"/>
        </button>
      </div>

      <!-- content -->
      <div class="container ">

        <p v-if="question.fromLibrary" class="font-semibold text-center max-w-2xl mt-6 -mb-2 mx-auto">
          Damit die statistische Auswertung der Ergebnisse möglich ist, lassen sich die Frage und die
          Antwortmöglichkeiten von Quizfragen aus der Fragenbibliothek nicht verändern.
        </p>


        <div class="p-8 grid" style="grid-template-columns: 1fr 5px 1fr;">

          <!-- left column -->
          <div>

            <!-- edit name -->
            <div class="form-group">
              <label class="form-label" for="name">{{
                  question.type === QUESTION_TYPES.SLIDE ? "Inhaltstext" : "Frage"
                }}</label>
              <input id="name" :disabled="question.fromLibrary" :value="question.title" class="form-input"
                     placeholder="Name"
                     type="text" @change="updateQuestion({ title: $event.target.value })">
            </div>

            <!-- edit question type -->
            <div class="form-group">
              <label class="form-label">Fragentyp</label>
              <label class="form-radio">
                <input :checked="question.type === QUESTION_TYPES.EXPERIENCE" :disabled="question.fromLibrary"
                       name="type"
                       type="radio" @click="updateQuestion({type: QUESTION_TYPES.EXPERIENCE})">
                <i class="form-icon"></i> {{ QUESTION_TYPES.EXPERIENCE }}
              </label>
              <label class="form-radio">
                <input :checked="question.type === QUESTION_TYPES.KNOWLEDGE" :disabled="question.fromLibrary"
                       name="type"
                       type="radio" @click="updateQuestion({type: QUESTION_TYPES.KNOWLEDGE})">
                <i class="form-icon"></i> {{ QUESTION_TYPES.KNOWLEDGE }}
              </label>
              <label class="form-radio">
                <input :checked="question.type === QUESTION_TYPES.SLIDE" :disabled="question.fromLibrary"
                       name="type"
                       type="radio" @click="updateQuestion({type: QUESTION_TYPES.SLIDE})">
                <i class="form-icon"></i> {{ QUESTION_TYPES.SLIDE }}
              </label>
            </div>

            <!-- switch between single and multiple choice -->
            <div v-if="question.type !== QUESTION_TYPES.SLIDE" class="form-group">
              <label class="form-label">Antworttyp</label>
              <label class="form-radio">
                <!-- if answerType is undefined, it is a single choice question -->
                <input :checked="!question.answerType || question.answerType === ANSWER_TYPES.SINGLE_CHOICE"
                       name="answer-type" type="radio"
                       :disabled="question.fromLibrary"
                       @click="updateQuestion({answerType: ANSWER_TYPES.SINGLE_CHOICE})">
                <i class="form-icon"></i> {{ ANSWER_TYPES.SINGLE_CHOICE }}
              </label>
              <label class="form-radio">
                <input :checked="question.answerType === ANSWER_TYPES.MULTIPLE_CHOICE" name="answer-type" type="radio"
                       :disabled="question.fromLibrary"
                       @click="updateQuestion({answerType: ANSWER_TYPES.MULTIPLE_CHOICE})">
                <i class="form-icon"></i> {{ ANSWER_TYPES.MULTIPLE_CHOICE }}
              </label>
            </div>

            <div v-if="!isOrganization && question.type !== QUESTION_TYPES.SLIDE" class="form-group">
              <label class="form-label">Zeitpunkte</label>
              <label class="form-checkbox">
                <input :checked="typeof question.timeBonus == 'undefined' ? true : question.timeBonus" type="checkbox"
                       @click="updateQuestion({timeBonus: $event.target.checked})">
                <i class="form-icon"></i> Zeitbonus aktiviert
              </label>
            </div>

            <!-- media preview -->
            <div>
              <label class="form-label">Mediendatei</label>

              <button class="btn" @click="openSelectMediaPopup">Mediendatei
                {{ mediaObject ? 'ändern' : 'auswählen' }}
              </button>
              <button v-if="mediaObject" class="btn btn-link" @click="updateQuestion({ media: null })">
                Mediendatei löschen
              </button>

              <!-- youtube preview -->
              <iframe v-if="youtubeVideoID" :src="`https://www.youtube-nocookie.com/embed/${youtubeVideoID}`"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen style="aspect-ratio: 16/9; width: 100%;"></iframe>

              <img v-if="mediaObject && mediaObject.meta.contentType.startsWith('image/')" :alt="mediaObject.name"
                   :src="mediaObject.url" :title="mediaObject.name" class="w-1/2 mt-2"/>
              <video v-if="mediaObject && mediaObject.meta.contentType.startsWith('video/')" :src="mediaObject.url"
                     :title="mediaObject.name" class="bg-black w-1/2 mt-2" controls/>
            </div>

            <!-- timer duration settings -->
            <div v-if="!isOrganization && question.type !== QUESTION_TYPES.SLIDE" class="form-group">
              <label class="form-label" for="timer-duration">Timerdauer in Sekunden</label>
              <input id="timer-duration"
                     :value="question.timerDuration >= 0 ? question.timerDuration : $store.state.settings.timerDuration"
                     class="form-input" placeholder="Timerdauer" type="number"
                     @change="updateQuestion({timerDuration: parseInt($event.target.value) >= 0 ? parseInt($event.target.value) : 30})">
            </div>

            <!-- timer sound settings -->
            <div v-if="!isOrganization && question.type !== QUESTION_TYPES.SLIDE" class="form-group">
              <label class="form-label" for="timer-sound">
                Timersound
                <span v-if="!this.question.timerSound">ist Standardeinstellung</span>
              </label>
              <button id="timer-sound" class="btn" @click="selectSound">Anderen Sound wählen</button>
              <p v-if="timerSound && timerSoundMediaObject" class="inline-block ml-4">Ausgewählt:
                {{ timerSoundMediaObject.name }}</p>
              <audio v-if="timerSound && timerSoundMediaObject" :src="timerSoundMediaObject.url"
                     class="block mt-2 w-full"
                     controls></audio>
            </div>

          </div>

          <!-- divider -->
          <div class="divider-vert"></div>

          <!-- question content column -->
          <div :class="question.type == QUESTION_TYPES.SLIDE && 'hidden'">


            <label class="mb-2 block">Antwortoptionen</label>

            <div v-for="(option, id) in question.options" class="input-group mb-2 flex flex-row items-center">

              <!-- correct answer switch -->
              <label class="form-switch">
                <input
                    :checked="id === question.correct || (question.correct.includes && question.correct.includes(id))"
                    :disabled="question.type !== QUESTION_TYPES.KNOWLEDGE"
                    type="checkbox" @click.prevent="toggleOptionCorrectness(id)"><i class="form-icon"></i>
              </label>

              <!-- edit option -->
              <input :disabled="question.fromLibrary" :placeholder="`Antwortmöglichkeit ${id + 1}`" :value="option"
                     class="form-input"
                     type="text" @change="updateOption(id, $event.target.value)">

              <!-- delete button -->
              <button :disabled="question.fromLibrary" class="btn btn-link">
                <font-awesome-icon :icon="['fas', 'times']" class="ml-2" size="lg"
                                   @click="removeOption(id)"></font-awesome-icon>
              </button>
            </div>
            <button :disabled="question.fromLibrary" class="btn btn-link" @click="addOption">Hinzufügen</button>

          </div>
        </div>

      </div>

      <!-- right button -->
      <div :class="{ 'invisible': isOrganization }" class="flex justify-center items-center mr-4"
           style="height: calc(100vh - 2.26rem);">
        <button class="button" @click="hasRightQuestion ? goToQuestion() : addQuestion()">
          <font-awesome-icon v-if="hasRightQuestion" icon="chevron-right" size="lg"/>
          <font-awesome-icon v-else icon="plus" size="lg"/>
        </button>
      </div>

    </div>
  </div>
</template>
<script>
import { db, organizationCollection } from "../plugins/firebase";
import SelectMediaPopup from "../components/popups/SelectMediaPopup";
import { injectComponentWithReturn } from "../main";
import InfoAndAuthBar from "../components/InfoAndAuthBar";
import { ANSWER_TYPES, QUESTION_TYPES } from "../constants/questions";
import { getMediaFileURL } from "@/store/media";

export default {
  components: { InfoAndAuthBar },
  computed: {
    isOrganization() {
      return !!this.quiz.members;
    },
    quiz() {
      if (this.$route.params.organizationid)
        return this.$store.state.organizations.find(o => o.id === this.$route.params.organizationid);
      else
        return this.$store.state.quiz.quizzes.find(q => q.id === this.$route.params.quizid);
    },
    question() {
      return this.quiz.questions[this.$route.params.question];
    },
    mediaObject() {
      return getMediaFileURL(this.question.media);
    },
    timerSound() {
      return this.question.timerSound || this.$store.state.settings.timerSound;
    },
    timerSoundMediaObject() {
      return getMediaFileURL(this.timerSound);
    },
    hasLeftQuestion() {
      return this.$route.params.question > 0;
    },
    hasRightQuestion() {
      return this.$route.params.question < this.quiz.questions.length - 1;
    },
    youtubeVideoID() {
      try {
        return new URL(this.question.media).searchParams.get("v");
      } catch (e) {
        return null;
      }
    }
  },
  data() {
    return {
      QUESTION_TYPES, ANSWER_TYPES
    }
  },
  methods: {
    goToQuestion(right = true) {
      this.$router.push({
        name: 'edit-question',
        params: {
          quizid: this.$route.params.quizid,
          question: right ? parseInt(this.$route.params.question) + 1 : parseInt(this.$route.params.question) - 1
        }
      });
    },
    updateOption(id, value) {
      const options = this.question.options;
      options[id] = value;
      this.updateQuestion({ options });
    },
    addQuestion() {
      const question = {
        id: this.quiz.questions.length,
        type: QUESTION_TYPES.KNOWLEDGE,
        answerType: ANSWER_TYPES.SINGLE_CHOICE,
        title: this.quiz.questions.length + 1 + ". Frage",
        options: ["Antwortoption 1", "Antwortoption 2", "Antwortoption 3", "Antwortoption 4"],
        correct: 0
      };
      db.collection("quizzes").doc(this.quiz.id).update({
        ...this.quiz,
        questions: [...this.quiz.questions, question]
      });
      this.$router.push({
        name: 'edit-question',
        params: { quizid: this.quiz.id, question: this.quiz.questions.length }
      });
      this.$store.commit("addMessage", "Frage erfolgreich erstellt");
    },
    toggleOptionCorrectness(id) {
      if (this.question.answerType === ANSWER_TYPES.MULTIPLE_CHOICE) {
        const correct = typeof this.question.correct !== "number" ? this.question.correct : [this.question.correct];
        const newCorrect = correct.includes(id) ? correct.filter(c => c !== id) : [...correct, id];

        if (newCorrect.length === 1)
          this.updateQuestion({ correct: newCorrect[0] });
        else
          this.updateQuestion({ correct: newCorrect });
      } else {
        this.updateQuestion({ correct: id });
      }
    },
    removeOption(id) {
      const options = this.question.options;
      options.splice(id, 1);
      this.updateQuestion({ options });
    },
    addOption() {
      const options = this.question.options;
      options.push("Unbenannt");
      this.updateQuestion({ options });
    },
    openSelectMediaPopup() {
      injectComponentWithReturn(SelectMediaPopup).then(media => {
        this.updateQuestion({ media });
      });
    },
    updateQuestion(content) {
      const questionsCopy = JSON.parse(JSON.stringify(this.quiz.questions)),
          questionID = this.$route.params.question;
      questionsCopy[questionID] = { ...questionsCopy[questionID], ...content };

      if (this.isOrganization)
        organizationCollection.doc(this.quiz.id).update({ questions: questionsCopy });
      else
        db.collection("quizzes").doc(this.quiz.id).update({ questions: questionsCopy });
    },
    selectSound() {
      injectComponentWithReturn(SelectMediaPopup, { audio: true }).then(media => {
        this.updateQuestion({ timerSound: media });
      });
    }
  },
  watch: {
    question: {
      handler() {
        if (this.question.answerType === ANSWER_TYPES.SINGLE_CHOICE && typeof this.question.correct !== "number")
          this.updateQuestion({ correct: 0 });
      }, deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.button {
  @apply bg-gray-200 shadow-lg border-2 border-gray-100 rounded-full w-12 h-12 flex justify-center items-center hover:border-gray-500 active:bg-gray-300;
}
</style>