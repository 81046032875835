<template>
  <div>
    <p class="font-bold">Fragenbibliothek</p>
    <button class="btn btn-primary" @click="addQuestion" v-if="isAdmin">Frage hinzufügen</button>

    <draggable :disabled="!isAdmin" :value="organization.questions" class="mt-4 grid grid-cols-3"
               group="people"
               @change="moveElementAndUpdate">
      <question-card v-for="(question, id) in organization.questions" :allow-edit="isAdmin" :organization="true"
                     :question="question" :question-i-d="id" :quiz="organization"/>
    </draggable>

  </div>
</template>
<script>
import { organizationCollection } from "@/plugins/firebase";
import { QUESTION_TYPES } from "@/constants/questions";
import QuestionCard from "@/components/edit-quiz/QuestionCard.vue";
import draggable from 'vuedraggable';

export default {
  components: { QuestionCard, draggable },
  props: ["organization", "isAdmin"],
  methods: {
    moveElementAndUpdate($event) {
      const questions = this.organization.questions.filter(() => true).move($event.moved.oldIndex, $event.moved.newIndex);
      organizationCollection.doc(this.organization.id).update({
        ...this.organization,
        questions
      });
    },
    addQuestion() {
      const newQuestion = {
        id: this.organization.questions.length,
        type: QUESTION_TYPES.EXPERIENCE,
        title: this.organization.questions.length + 1 + ". Frage",
        options: ["Antwortoption 1", "Antwortoption 2", "Antwortoption 3", "Antwortoption 4"],
        correct: 0
      };
      organizationCollection.doc(this.organization.id).update({
        ...this.organization,
        questions: [...this.organization.questions, newQuestion]
      });
    }
  }
}
</script>