<template>
  <div v-if="settings">

    <div class="form-horizontal max-w-2xl">
      <p class="font-bold">Deine Quizeinstellungen</p>

      <div class="form-group">
        <label class="form-label col-4" for="timer-duration">Timerdauer in Sekunden</label>
        <input id="timer-duration" :value="settings.timerDuration" class="form-input col-8"
               placeholder="Standard Timerdauer" type="number"
               @change="updateSetting({timerDuration: parseInt($event.target.value) || 30})">
      </div>

      <div class="form-group">
        <label class="form-label col-4" for="timer-sound">Timersound</label>
        <div class="col-8">
          <audio v-if="settings.timerSound && timerSoundPath" :src="timerSoundPath.url" class="max-h-full"
                 controls></audio>
          <button v-else id="timer-sound" class="btn btn-primary" @click="selectMedia">Sound wählen</button>
          <button v-if="settings.timerSound" class="btn btn-link ml-2" @click="updateSetting({timerSound: null})">
            Sound löschen
          </button>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label col-4" for="indiv-logo">Fachstellenbezogenes Logo</label>
        <div class="col-8">
          <img v-if="settings.logo && logoPath" :src="logoPath.url" :title="logoPath.name"
               class="max-h-full h-32 inline-block"/>
          <button v-else id="indiv-logo" class="btn btn-primary" @click="selectLogo">Bild auswählen</button>
          <button v-if="settings.logo" class="btn btn-link ml-2" @click="updateSetting({ logo: null })">
            Logo löschen
          </button>
        </div>
      </div>

    </div>

    <div>
      <p class="font-bold">
        {{ this.$store.state.organizations.length > 1 ? 'Deine Organisationen' : 'Deine Organisation' }}</p>
      <ul>
        <organization-row v-for="org in this.$store.state.organizations" :organization="org" :user="user"/>
      </ul>
    </div>

  </div>
</template>
<script>
import { injectComponentWithReturn } from "@/main";
import SelectMediaPopup from "../popups/SelectMediaPopup";
import OrganizationRow from "@/components/settings/OrganizationRow.vue";
import { globalSettingsCollection } from "../../plugins/firebase";

export default {
  components: { OrganizationRow },
  methods: {
    updateSetting(update) {
      globalSettingsCollection.doc(this.$store.state.user.uid).update(update);
    },
    selectMedia() {
      injectComponentWithReturn(SelectMediaPopup, { audio: true }).then(media => {
        this.updateSetting({ timerSound: media });
      });
    },
    selectLogo() {
      injectComponentWithReturn(SelectMediaPopup).then(media => {
        this.updateSetting({ logo: media });
      });
    }
  },
  computed: {
    settings() {
      return this.$store.state.settings;
    },
    user() {
      return this.$store.state.user;
    },
    timerSoundPath() {
      return this.$store.state.media.mediaFiles.find(m => m.ref.fullPath.includes(this.settings.timerSound));
    },
    logoPath() {
      return this.$store.state.media.mediaFiles.find(m => m.ref.fullPath.includes(this.settings.logo));
    },
  }
}
</script>