<template>
  <form class="form-horizontal mb-8 max-w-2xl" @submit.prevent="upload(files[0])">

    <!-- file input -->
    <div class="form-group">
      <label class="form-label col-3 col-sm-12" for="files">Datei</label>
      <input id="files" ref="files"
             accept="image/jpeg,image/png,video/mp4,video/x-matroska,image/webp,image/gif,audio/mp3"
             class="form-input col-9 col-sm-12" required type="file" @change="files = $event.target.files">
    </div>

    <!-- filename input -->
    <div class="form-group">
      <label class="form-label col-3 col-sm-12" for="filename">Dateinamen</label>
      <input id="filename" v-model="name" class="form-input col-9 col-sm-12" placeholder="Dateinamen" required
             type="text">
    </div>

    <!-- button -->
    <div class="form-group">
      <div class="col-3 col-sm-12"/>
      <button :class="{'loading' : uploading, 'disabled' : !files || !name || !name.length}"
              class="btn col-9 col-sm-12">
        Hochladen
      </button>
    </div>
  </form>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { globalMediaFilesCollection, storage } from "@/plugins/firebase";
import firebase from "firebase";

export default {
  data() {
    return {
      files: null,
      uploading: null,
      name: null,
    }
  },
  methods: {
    async upload(file) {
      this.uploading = true;

      const path = `media/${uuidv4()}-${this.name}`;
      const ref = storage.ref(path);
      const task = await ref.put(file);

      await globalMediaFilesCollection.doc(this.$store.state.user.uid).update({
        files: firebase.firestore.FieldValue.arrayUnion({
          path: path,
        })
      });

      this.uploading = false;
      this.$store.commit("addMediaFile", {
        ref: task.ref,
        url: await ref.getDownloadURL(),
        meta: await ref.getMetadata(),
        name: task.ref.name.substr(37)
      });

      // reset form
      this.name = "";
      this.$refs.files.value = "";
      this.files = null;
    }
  },
}
</script>