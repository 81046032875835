<template>
  <li class="list-none">
    <div class="border p-4">
      <p>
        <strong>{{ organization.name }}</strong>
        <span class="inline-block rounded-full bg-black text-white ml-2 px-2"
              v-if="organization.admins.includes(user.uid)">Admin</span>
      </p>

      <router-link to="" class="btn btn-primary mb-4" :to="`/evaluation/${organization.id}`" target="_blank" v-if="isAdmin">
        Auswertung im neuen Tab öffnen
      </router-link>

      <p v-if="!isAdmin">Du bist Mitglied der Organisation und hast Zugriff auf die Fragenbibliothek von
        {{ organization.name }}. Die
        Antworten auf Fragen aus der Fragenbibliothek sind für die Administratoren der Organisation für
        Auswertungszwecke sichtbar. </p>

      <!-- organization dashboard name -->
      <div v-if="isAdmin">
        <div class="form-horizontal max-w-2xl">
          <p class="text-bold">Konfiguration der Organisation</p>

          <!-- application title -->
          <div class="form-group">
            <label class="form-label col-4" for="dashboard-title">Titel der Anwendung</label>
            <input id="dashboard-title" :value="organization.dashboardTitle" class="form-input col-8" type="text"
                   @change="update({ dashboardTitle: $event.target.value })"/>
          </div>

          <!-- imprint -->
          <div class="form-group">
            <label class="form-label col-4" for="imprint">Impressum für Teilnehmer</label>
            <input id="imprint" :value="organization.imprint" class="form-input col-8" type="text"
                   @change="update({ imprint: $event.target.value })"/>
          </div>

          <!-- privacy policy -->
          <div class="form-group">
            <label class="form-label col-4" for="privacy-policy">Datenschutzerklärung für Teilnehmer</label>
            <input id="privacy-policy" :value="organization.privacyPolicy" class="form-input col-8" type="text"
                   @change="update({ privacyPolicy: $event.target.value })"/>
          </div>

          <!-- logo -->
          <div class="form-group">
            <label class="form-label col-4" for="logo">Logo für Teilnehmer</label>
            <div class="col-8">
              <img v-if="organization.logo && logoPath" :src="logoPath.url" :title="logoPath.name"
                   class="max-h-full h-32 inline-block"/>
              <button v-else id="logo" class="btn btn-primary" @click="selectLogo">Bild auswählen</button>
              <button v-if="organization.logo" class="btn btn-link ml-2" @click="update({ logo: null })">
                Logo löschen
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- members -->
      <div v-if="isAdmin">
        <p class="text-bold">
          Mitglieder
          <button title="Mitglieder erneut laden" @click="loadMembers">
            <font-awesome-icon icon="sync" size="xs"/>
          </button>
        </p>
        <ul v-if="members">
          <li v-for="member in members.filter(m => organization.members.includes(m.uid))" :key="member.uid">
            {{ member.email }}
            <button title="Nutzer löschen" @click="deleteUser(member)">
              <font-awesome-icon icon="trash" size="xs"/>
            </button>
          </li>
        </ul>
        <spinner v-else/>
      </div>


      <!-- invitations -->
      <div v-if="isAdmin" class="mb-6">
        <p class="text-bold">Einladungen</p>
        <ul v-if="organization.invitations && organization.invitations.length > 0">
          <li v-for="invitation in organization.invitations" :key="invitation">
            {{ invitation }}
            <button title="Einladung löschen" @click="deleteInvitation(invitation)">
              <font-awesome-icon icon="trash" size="xs"/>
            </button>
          </li>
        </ul>
        <p v-else>Keine Einladungen</p>

        <form class="input-group max-w-md" @submit.prevent="inviteMember">
          <input type="email" class="form-input" v-model="invitationMail" placeholder="E-Mail-Adresse">
          <button class="btn btn-primary input-group-btn" type="submit">Einladen</button>
        </form>
      </div>

      <!-- question library -->
      <organization-question-library :is-admin="isAdmin" :organization="organization"/>

    </div>
  </li>
</template>
<script>
import { getEvaluationData, getOrganizationMembers, organizationCollection } from "@/plugins/firebase";
import Spinner from "@/components/Spinner.vue";
import firebase from "firebase/app";
import OrganizationQuestionLibrary from "@/components/settings/OrganizationQuestionLibrary.vue";
import { injectComponentWithReturn } from "../../main";
import ConfirmPopup from "../popups/ConfirmPopup.vue";
import SelectMediaPopup from "../popups/SelectMediaPopup.vue";

export default {
  components: { OrganizationQuestionLibrary, Spinner },
  props: ["organization", "user"],
  data() {
    return {
      members: null,
      invitationMail: ""
    }
  },
  computed: {
    isAdmin() {
      return this.organization.admins.includes(this.$store.state.user.uid);
    },
    logoPath() {
      return this.$store.state.media.mediaFiles.find(m => m.ref.fullPath.includes(this.organization.logo));
    },
  },
  methods: {
    deleteUser(member) {
      injectComponentWithReturn(ConfirmPopup, {
        question: `Möchtest du ${member.email} wirklich aus der Organisation entfernen?`,
        confirm: "Ja, entfernen"
      }).then(() => {
        organizationCollection.doc(this.organization.id).update({
          members: firebase.firestore.FieldValue.arrayRemove(member.uid)
        });
      });
    },
    deleteInvitation(invitation) {
      injectComponentWithReturn(ConfirmPopup, {
        question: `Möchtest du die Einladung an ${invitation} wirklich löschen?`,
        confirm: "Ja, löschen"
      }).then(() => {
        organizationCollection.doc(this.organization.id).update({
          invitations: firebase.firestore.FieldValue.arrayRemove(invitation)
        });
      });
    },
    inviteMember() {
      organizationCollection.doc(this.organization.id).update({
        invitations: firebase.firestore.FieldValue.arrayUnion(this.invitationMail.toLowerCase())
      });
      this.invitationMail = "";
    },
    loadMembers() {
      const self = this;
      self.members = null;
      getOrganizationMembers({ organizationID: this.organization.id }).then((result) => {
        self.members = result.data;
      });
    },
    update(value) {
      organizationCollection.doc(this.organization.id).update({
        ...value
      });
    },
    selectLogo() {
      injectComponentWithReturn(SelectMediaPopup).then(media => {
        this.update({ logo: media });
      });
    }
  },
  created() {
    if (this.isAdmin) {
      this.loadMembers();
      // getEvaluationData({ organizationID: this.organization.id }).then(({ result }) => {
      //   console.log(result)
      // });
    }
  }
}
</script>