import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import { applyInvitation, auth, invitationsCollection } from "./firebase";
import router from "../router";

export const uiConfig = {
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    signInFlow: 'popup',
    callbacks: {
        signInSuccessWithAuthResult(result) {
            // check if registration and add invite
            if(result.additionalUserInfo.isNewUser && router.currentRoute.query.i) {
                const invitation = router.currentRoute.query.i;

                applyInvitation({ invitation });
            }

            router.push("/dashboard");
        }
    }
};

export const ui = new firebaseui.auth.AuthUI(auth);