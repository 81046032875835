<template>
  <div>

    <p class="h4">Dateien anschauen oder hochladen</p>

    <media-upload/>

    <div class="divider"></div>

    <div v-if="filteredMediaFiles == null" class="loading"/>

    <div class="grid mt-8 gap-4 grid-cols-5">
      <media-card v-for="object in filteredMediaFiles" v-if="filteredMediaFiles != null" :object="object"
                  @delete="filteredMediaFiles.remove(object)"/>
    </div>

    <h2 class="mt-20">Öffentliche Medienbibliothek der ginko Stiftung für Prävention</h2>

    <p>Die folgenden Bilder können Sie in Ihren Quizzen ohne weitere vorherige Zustimmung nutzen. Sollten Sie Fragen
      oder Änderungswünsche haben, melden Sie sich bitte bei Lea Würzinger unter <a
          href="mailto:l.wuerzinger@ginko-stiftung.de"
          target="_blank">l.wuerzinger@ginko-stiftung.de</a>. </p>

    <div class="grid mt-8 gap-4 grid-cols-5">
      <media-card v-for="object in $store.state.media.publicMediaFiles"
                  v-if="$store.state.media.publicMediaFiles != null" deleteDisabled :object="object"/>
    </div>

  </div>
</template>

<script>
import MediaCard from "./MediaCard";
import { deleteMediaFile, filteredMediaFiles } from "@/store/media";
import MediaUpload from "@/components/MediaUpload.vue";

export default {
  name: "Mediathek",
  components: { MediaCard, MediaUpload },
  computed: { filteredMediaFiles },
};
</script>
<style>
.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}
</style>