<template>
  <div>
    <div v-if="!$store.state.initialized" class="w-screen h-screen flex justify-center items-center">
      <Spinner />
    </div>
    <router-view v-else />

    <MessageElement />

    <component :is="child.component" v-for="child in injected" v-bind="child.props"
               @failure="child.props.onFailure($event)" @success="child.props.onSuccess($event)"></component>
  </div>
</template>
<script>
import Spinner from "./components/Spinner";
import MessageElement from "./components/gui-components/MessageElement";

export default {
  components: { MessageElement, Spinner },
  data() {
    return {
      injected: {},
    }
  },
  created() {
    this.$root.$on("inject-component", data => {

      const id = data.props.id, self = this;

      function removeInstance() {
        delete self.injected[id];
        self.$forceUpdate();
      }

      function onSuccess(data) {
        self.$root.$emit(`${id}-success`, data);
        removeInstance();
      }

      function onFailure(data) {
        self.$root.$emit(`${id}-failure`, data);
        removeInstance();
      }

      data.props = {
        ...data.props,
        onSuccess,
        onFailure
      }

      this.injected[id] = data
      this.$forceUpdate();
    });

  }
}
</script>