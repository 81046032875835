import { firestoreAction } from 'vuexfire'
import { quizCollection } from "@/plugins/firebase";

export const quizStore = {
    state: {
        quizzes: [],
    },
    mutations: {
        setQuizzes: (state, quizzes) => state.quizzes = quizzes
    },
    actions: {
        loadQuizzes: firestoreAction(({ bindFirestoreRef, rootState: state }) => {
            return bindFirestoreRef('quizzes', quizCollection.where("creator", "==", state.user.uid).orderBy('created', 'desc'))
        }),
    }
}