import { checkAndDeleteStorageObject, globalMediaFilesCollection, storage } from "../plugins/firebase";
import { firestoreAction } from "vuexfire";
import store from "@/store/index";

export const mediaStore = {
    state: {
        mediaFiles: null,
        publicMediaFiles: null,
        personalMediaFiles: null
    },
    mutations: {
        setMediaFiles: (state, objects) => state.mediaFiles = objects,
        setPublicMediaFiles: (state, objects) => state.publicMediaFiles = objects,
        deleteMediaFile: (state, file) => state.mediaFiles = state.mediaFiles.filter(f => f !== file),
        addMediaFile: (state, file) => state.mediaFiles.push(file),
    },
    actions: {
        async loadMediaFiles(store) {
            const rootRef = storage.ref('media/');
            const res = await rootRef.listAll();

            // folders currently ignored
            // res.prefixes.forEach((folderRef) => { });

            const mediaFiles = [];

            await Promise.all(res.items.map(async fileRef => {
                const url = await fileRef.getDownloadURL();
                const meta = await fileRef.getMetadata();
                mediaFiles.push({ ref: fileRef, url, meta, name: fileRef.name.substr(37) });
            }));

            store.commit("setMediaFiles", mediaFiles)
        },
        async loadPublicMediaFiles(store) {
            const rootRef = storage.ref('media/predefined-ginko');
            const res = await rootRef.listAll();

            const mediaFiles = [];

            await Promise.all(res.items.map(async fileRef => {
                const url = await fileRef.getDownloadURL();
                const meta = await fileRef.getMetadata();
                mediaFiles.push({ ref: fileRef, url, meta });
            }));

            store.commit("setPublicMediaFiles", mediaFiles)
        },
        loadPersonalMediaFiles: firestoreAction(({ bindFirestoreRef, rootState }) => {
            return bindFirestoreRef('personalMediaFiles', globalMediaFilesCollection.doc(rootState.user.uid));
        }),
        async deleteMediaFile(store, file) {
            await checkAndDeleteStorageObject({ filePath: file.ref.fullPath });
            store.commit("deleteMediaFile", file)
        }
    }
}

export function filteredMediaFiles() {
    const self = this;

    function mediaFilesFilterFunction(m) {
        return self.$store.state.media.personalMediaFiles.files.find((f) => f.path === m.ref.fullPath)
    }

    // sort by image, video and audio and name
    return this.$store.state.media.mediaFiles.filter(mediaFilesFilterFunction).sort((a, b) => {
        if (a.meta.contentType.startsWith("image") && !b.meta.contentType.startsWith("image"))
            return -1;
        if (b.meta.contentType.startsWith("image") && !a.meta.contentType.startsWith("image"))
            return 1;
        if (a.meta.contentType.startsWith("video") && !b.meta.contentType.startsWith("video"))
            return -1;
        if (b.meta.contentType.startsWith("video") && !a.meta.contentType.startsWith("video"))
            return 1;
        if (a.meta.contentType.startsWith("audio") && !b.meta.contentType.startsWith("audio"))
            return -1;
        if (b.meta.contentType.startsWith("audio") && !a.meta.contentType.startsWith("audio"))
            return 1;
        return a.name.localeCompare(b.name);
    });
}

export function getMediaFileURL(mediaFile) {
    return store.state.media.mediaFiles.filter(file => file.ref.fullPath === mediaFile)[0] ||
        store.state.media.publicMediaFiles.filter(file => file.ref.fullPath === mediaFile)[0];
}