import firebase from "firebase/app";
import { firebaseConfig } from "./firebase-config";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

firebase.initializeApp(firebaseConfig);

export const storage = firebase.storage();
export const db = firebase.firestore();
export const auth = firebase.auth();

export const functions = firebase.app().functions("europe-west3");

export const getOrganizationMembers = functions.httpsCallable('getOrganizationMembers');

export const getEvaluationData = functions.httpsCallable('getEvaluationData');
export const applyInvitation = functions.httpsCallable('applyInvitation');
export const checkAndDeleteStorageObject = functions.httpsCallable('checkAndDeleteStorageObject');

export const quizCollection = db.collection('quizzes');

// quizCollection.doc("zZ80sDGreyjtw262FC2k").get().then((doc) => {
//     console.log(doc.data());
// });

export function organizationCollectionForUID(uid) {
    return db.collection('organizations').where("members", "array-contains", uid);
}

export const organizationCollection = db.collection('organizations');

export const globalSettingsCollection = db.collection("settings");

export const globalMediaFilesCollection = db.collection("media-files");

export const invitationsCollection = db.collection("invitations");