export const QUESTION_TYPES = {
    EXPERIENCE: "Erfahrungsfrage",
    KNOWLEDGE: "Wissensfrage",
    SLIDE: "Folie"
}

export const ANSWER_TYPES = {
    SINGLE_CHOICE: "Single Choice",
    MULTIPLE_CHOICE: "Multiple Choice"
}

export function getPrimaryOrganization(self) {
    return self.$store.state.organizations[0];
}