import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import 'spectre.css/dist/spectre.min.css'
import { v4 as uuidv4 } from 'uuid';

// set favicon depending on url
const hostnameMap = {
    "quiz.caritas-neuss.de": "/caritas.png",
    "quiz.suchtgeschichte.nrw.de": "/ginko.png",
    "localhost": "/ginko.png"
}

document.querySelector("link[rel*='icon']").href = hostnameMap[window.location.hostname];

Array.prototype.remove = function (val) {
    for (let i = 0; i < this.length; i++) {
        if (this[i] === val) {
            this.splice(i, 1);
            i--;
        }
    }
    return this;
}
Array.prototype.move = function (old_index, new_index) {
    if (new_index >= this.length) {
        let k = new_index - this.length + 1;
        while (k--)
            this.push(undefined);
    }

    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    return this;
}
Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
}

store.dispatch("subscribeFirebaseUser");

Vue.config.productionTip = false

const vue = new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')

export function injectComponentWithReturn(component, props = {}) {
    // generate id for the component
    const id = uuidv4();

    return new Promise((resolve, reject) => {
        vue.$emit("inject-component", { component, props: { ...props, id } });
        vue.$on(`${id}-success`, data => resolve(data));
        vue.$on(`${id}-failure`, data => reject(data));
    })
}

// router.push("/evaluation/3F8QSNCNpdoCVeROUSuU")