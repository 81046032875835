<template>
  <div>

    <info-and-auth-bar :title="`Bearbeitung Quizvorlage ${quiz ? quiz.name : ''}`"/>

    <div v-if="quiz" class="container p-8">

      <h1 class="h1">Allgemeine Informationen</h1>

      <div class="columns">
        <div class="column">

          <div class="form-group">
            <label class="form-label" for="name">Name</label>
            <input id="name" :value="quiz.name" class="form-input" placeholder="Name" type="text"
                   @change="updateQuiz({name: $event.target.value})" :disabled="!quiz.template">
          </div>

          <div class="form-group">
            <label class="form-label" for="description">Beschreibung</label>
            <textarea id="description" :value="quiz.description" class="form-input" placeholder="Beschreibung" rows="6"
                      @change="updateQuiz({description: $event.target.value})" :disabled="!quiz.template"></textarea>
          </div>

        </div>
        <div v-if="!quiz.template" class="column">

          <div class="form-group">
            <label class="form-label" for="location">Ort, Schule, Klasse</label>
            <input id="location" :value="quiz.location" class="form-input" placeholder="Ort, Schule, Klasse" type="text"
                   @keydown.enter="updateQuiz({location: $event.target.value})"
                   @change="updateQuiz({location: $event.target.value})">
          </div>

          <div class="form-group">
            <label class="form-label" for="stufe">Klassenstufe, "-" um das Quiz aus der Auswertung
              auszuschließen</label>
            <input id="stufe" :value="quiz.stufe" class="form-input" placeholder="6/7/8" type="text"
                   @keydown.enter="updateQuiz({stufe: $event.target.value})"
                   @change="updateQuiz({stufe: $event.target.value})">
          </div>

          <div class="form-group">
            <button :disabled="!quiz.location || !quiz.stufe" class="btn btn-primary mt-7 w-full"
                    @click="$router.push(`/p/${quiz.id}`)">Präsentationsmodus starten
            </button>
          </div>

          <div class="form-group">
            <!-- todo: only if answers were given -->
            <button :class="exporting && 'loading'" :disabled="!quiz.location" class="btn w-full" @click="exportQuiz">
              Antworten exportieren
            </button>
          </div>


        </div>
      </div>


      <h1 class="h1 mt-8">Fragen</h1>

      <draggable :value="quiz.questions" class="grid grid-cols-2 gap-10" group="people" @change="moveElementAndUpdate"
                 :disabled="!quiz.template">
        <question-card v-for="(question, qid) in quiz.questions" :question="question" :question-i-d="qid"
                       :allow-edit="quiz.template" :quiz="quiz"/>
      </draggable>

      <div class="btn-group btn-group-block mt-8 max-w-md">
        <button v-if="quiz.template" class="btn btn-primary" @click="addQuestion">Frage hinzufügen</button>
        <button v-if="quiz.template" class="btn" @click="addQuestionFromLibrary">Aus Fragenbibliothek hinzufügen
        </button>
      </div>

    </div>

  </div>
</template>
<script>
import InfoAndAuthBar from "../components/InfoAndAuthBar";
import { db, quizCollection } from "../plugins/firebase";
import draggable from 'vuedraggable';
import QuestionCard from "../components/edit-quiz/QuestionCard";
import { parse } from "json2csv";
import { QUESTION_TYPES } from "@/constants/questions";
import { injectComponentWithReturn } from "../main";
import SelectQuestionFromQuestionLibraryPopup from "../components/popups/SelectQuestionFromQuestionLibraryPopup.vue";
import { getPrimaryOrganization } from "../constants/questions";

export default {
  methods: {
    moveElementAndUpdate($event) {
      const questions = this.quiz.questions.filter(() => true).move($event.moved.oldIndex, $event.moved.newIndex);
      this.updateQuiz({ questions });
    },
    updateQuiz(quiz) {
      db.collection("quizzes").doc(this.quiz.id).update(quiz);
    },
    addQuestion() {
      const question = {
        id: this.quiz.questions.length,
        type: QUESTION_TYPES.KNOWLEDGE,
        title: this.quiz.questions.length + 1 + ". Frage",
        options: ["Antwortoption 1", "Antwortoption 2", "Antwortoption 3", "Antwortoption 4"],
        correct: 0
      };
      this.updateQuiz({ ...this.quiz, questions: [...this.quiz.questions, question] });
    },
    addQuestionFromLibrary() {
      injectComponentWithReturn(SelectQuestionFromQuestionLibraryPopup, { questions: this.getPrimaryOrganization.questions }).then(question => {
        this.updateQuiz({
          ...this.quiz,
          questions: [...this.quiz.questions, { ...question, id: this.quiz.questions.length, fromLibrary: true }]
        });
      })
    },
    async exportQuiz() {
      this.exporting = true;

      // get votes
      const votes = (await quizCollection.doc(this.quiz.id).collection("votes").orderBy("question", "asc").orderBy("userid", "asc").get()).docs.map(doc => ({
        ...doc.data(),
        fragenNummer: doc.data().question + 1,
        fragenTyp: this.quiz.questions[doc.data().question].type,
        frage: this.quiz.questions[doc.data().question].title,
        optionen: doc.data().options ? doc.data().options.join(", ") : ""
      }));

      // parse and remove umlaute
      const csv = parse(votes, { fields: ["fragenNummer", "fragenTyp", "frage", "name", "option", "optionen"], delimiter: ";" });
      const cleanedCSV = csv.replaceAll("ä", "ae").replaceAll("ö", "oe").replaceAll("ü", "ue").replaceAll("ß", "ss");

      // insert link and click it
      let link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-16le," + encodeURI(cleanedCSV));
      link.setAttribute("download", `${this.quiz.name}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.exporting = false;
    }
  },
  data() {
    return {
      exporting: false
    }
  },
  components: { QuestionCard, InfoAndAuthBar, draggable },
  computed: {
    getPrimaryOrganization,
    quiz() {
      return this.$store.state.quiz.quizzes.find(q => q.id === this.$route.params.id)
    }
  }
}
</script>