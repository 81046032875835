<template>
  <div class="w-screen h-screen">

    <!-- background image -->
    <img class="w-screen h-64 object-cover" src="../assets/login-welcome-screen/background-tv-final.png"
         style="object-position: 100% 35%"/>


    <div class="max-w-5xl mx-auto grid gap-12 grid-cols-1 lg:grid-cols-login">

      <!-- info -->
      <div class="shadow-2xl bg-white -mt-24 rounded-2xl p-8 flex  flex-col justify-between w-11/12 mx-2">
        <div>
        <h1 class="text-xl ">Herzlichen Willkommen auf der Quizplattform der Landeskampagne „Sucht hat immer eine
          Geschichte“</h1>
        <p>QuizTime ermöglicht das Erstellen individualisierter Quizze und das Nutzen von Quizvorlagen der Landesfachstelle
          Prävention NRW. Die Quizze können mit Erfahrungs-, Wissens- und Multiple-Choice-Fragen gestaltet und durch
          Bilder, Videos und Sounds ergänzt werden.</p>
        <p>Das Angebot ist für den Einsatz für suchtpräventive Veranstaltungen in Schulen, Jugendfreizeiteinrichtungen
          und Schulungen für Multiplikator:innen vorgesehen.</p>
        </div>

        <div class="mt-8 space-x-4 ">
          <img class="w-2/6 inline-block"
               src="@/assets/login-welcome-screen/suchtgeschichte.jpg"/>
          <img class="w-2/6 inline-block"
               src="@/assets/login-welcome-screen/ginko.jpg"/>
          <img class="h-16 object-contain inline-block"
               src="@/assets/login-welcome-screen/suchtkooperation.jpg"/>
        </div>
      </div>

      <!-- login -->
      <div class="max-w-min lg:max-w-full mx-auto">
        <h2 class="mt-8 mb-6 text-xl">Anmeldung Quiz Inhaltsverwaltung</h2>
        <div id="firebaseui" style="width: 360px"></div>
        <p class="mt-6 text-xs text-gray-500">Die Registrierung und Nutzung der Anwendung ist ausschließlich
          Präventionsfachkräften, die der ginko Stiftung für Prävention angehören, vorbehalten. Andere Konten können
          jederzeit gelöscht oder deaktiviert werden. </p>
      </div>

    </div>


  </div>
</template>
<script>
import { ui, uiConfig } from "../plugins/firebase-ui";
import "firebaseui/dist/firebaseui.css"
import Spinner from "../components/Spinner";

export default {
  components: { Spinner },
  mounted() {
    ui.start("#firebaseui", uiConfig);
  },
}
</script>