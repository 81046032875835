<template>
  <div :title="object.name"
       class="relative h-48 w-full max-h-48 overflow-hidden flex flex-col justify-center items-center border-2 border-dashed"
       @click="$emit('click')">

    <img v-if="object.meta.contentType.startsWith('image/')" :src="object.url" alt="Visual"
         class="object-cover min-h-full" />
    <video v-if="object.meta.contentType.startsWith('video/')" :src="object.url" class="h-8/12 bg-black" controls/>
    <audio v-if="object.meta.contentType.startsWith('audio/')" :src="object.url" class="max-w-full" controls
           controlslist="nodownload,nofullscreen,noremoteplayback" />

    <p class="-mb-6 mt-2">{{ object.name }}</p>

    <button v-if="!deleteDisabled" class="absolute bottom-0 right-0 m-2 btn btn-action s-circle bg-primary"
            @click="deleteFile">
      <font-awesome-icon v-if="!isDeleting" class="w-3 mx-auto" icon="trash" />
      <spinner v-else class="white" />
    </button>

  </div>
</template>
<script>
import { injectComponentWithReturn } from "../../main";
import ConfirmPopup, { deleteQuestion } from "../popups/ConfirmPopup.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  components: { Spinner },
  methods: {
    deleteFile() {
      const stringType = `die Datei mit dem Namen ${this.object.name}`;
      injectComponentWithReturn(ConfirmPopup, { question: deleteQuestion(stringType), confirm: "Löschen" }).then(async () => {
        this.isDeleting = true;
        await this.$store.dispatch("deleteMediaFile", this.object)
        this.isDeleting = false;
      })
    }
  },
  data() {
    return {
      deleteActive: false,
      isDeleting: false
    }
  },
  props: {
    object: {
      type: Object
    },
    deleteDisabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>