import store from "@/store";

export function hasVerticalScroll() {
    return document.documentElement.scrollHeight > window.innerHeight;
}

export function infoBarTooLarge(infoBar) {
    return infoBar.offsetHeight / window.innerHeight > 0.3
}

export function addUniqueZoomMessage() {
    const text = "Es sieht so aus, als ob du eine sehr große Zoomstufe oder ein sehr kleines Display hast. Bitte stelle mit dem Zoom sicher, dass die Anzeige korrekt ist.";

    if (store.state.messages.filter(m => m.text === text).length == 0)
        store.commit("addMessage", text);
}