<template>
  <div>

    <info-and-auth-bar/>

    <!-- tabs -->
    <ul class="tab tab-block select-none">
      <li v-for="item in MENU_OPTIONS" :class="selected === item ? 'active' : null" class="tab-item">
        <a @click="selected = item">{{ item }}</a>
      </li>
    </ul>

    <!-- actual content -->
    <div class="p-8">

      <!-- quiz templates -->
      <div v-if="selected === MENU_OPTIONS.QUIZ_TEMPLATES">

        <!-- show own quiz templates -->
        <h2 class="h2 my-4">Eigene Quizvorlagen</h2>
        <div class="grid grid-cols-3 gap-8">
          <quiz-card v-for="quiz in quizTemplates" :quiz="quiz"/>

          <!-- add quiz template -->
          <button class="block border-dashed border-4 border-gray-100 flex justify-center items-center"
                  @click="createQuizTemplate">
            <span class="text-xl font-semibold my-4">Quizvorlage erstellen</span>
          </button>
        </div>

        <!-- clone predefined quiz templates -->
        <div class="mt-16">
          <h2 class="h2 my-4">Vordefinierte Quizvorlagen</h2>
          <div class="grid grid-cols-3 gap-8">
            <quiz-card v-for="quiz in predefinedQuizTemplates" :quiz="quiz"/>
          </div>
        </div>
      </div>


      <!-- quizzes -->
      <div v-if="selected === MENU_OPTIONS.QUIZZES">
        <div class="grid grid-cols-3 gap-8">
          <quiz-card v-for="quiz in quizzes" :quiz="quiz"/>
        </div>
        <p v-if="quizzes.length == 0" class="text-center mx-auto">Noch keine Quizze durchgeführt. Erstelle im ersten Tab
          ein Quiz und klicke dann auf durchführen! </p>
      </div>

      <!-- trashed quizzes -->
      <div v-if="selected === MENU_OPTIONS.TRASH" class="grid grid-cols-3 gap-8">
        <quiz-card v-for="quiz in trashedQuizzes" :quiz="quiz"/>
      </div>

      <mediathek v-if="selected === MENU_OPTIONS.MEDIA"/>

      <settings v-if="selected === MENU_OPTIONS.SETTINGS"/>

    </div>


  </div>
</template>
<script>
import QuizCard from "../components/dashboard/QuizCard";
import Mediathek from "../components/dashboard/Mediathek";
import InfoAndAuthBar from "../components/InfoAndAuthBar";
import { quizCollection } from "../plugins/firebase";
import Settings from "../components/dashboard/Settings";
import { getPrimaryOrganization } from "@/constants/questions";
import { predefinedQuizTemplates } from "@/constants/quiz-templates";

export const MENU_OPTIONS = {
  QUIZ_TEMPLATES: "Quizvorlagen",
  QUIZZES: "Durchgeführte Quizze",
  MEDIA: "Medienbibliothek",
  TRASH: "Papierkorb",
  SETTINGS: "Einstellungen"
}

export default {
  components: { Settings, InfoAndAuthBar, Mediathek, QuizCard },
  data() {
    return {
      MENU_OPTIONS,
      predefinedQuizTemplates
    }
  },
  methods: {
    createQuizTemplate() {
      // todo: shorter ids
      quizCollection.add({
        name: "Unbenannte Quizvorlage",
        template: true,
        description: "",
        creator: this.$store.state.user.uid,
        organization: getPrimaryOrganization(this).id,
        questions: [],
        created: Date.now()
      });
    }
  },
  computed: {
    quizTemplates() {
      return this.$store.state.quiz.quizzes.filter(q => q.template && !q.deleted);
    },
    quizzes() {
      return this.$store.state.quiz.quizzes.filter(q => !q.template && !q.deleted);
    },
    trashedQuizzes() {
      return this.$store.state.quiz.quizzes.filter(q => q.deleted);
    },
    selected: {
      get() {
        return this.$store.state.selectedDashboardTab;
      },
      set(val) {
        return this.$store.commit("setSelectedDashboardTab", val);
      }
    }
  }
}
</script>