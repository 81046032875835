<template>
  <PopupContainer style="z-index: 100">
    <div class="card max-h-screen flex flex-col">
      <div class="card-header">
        <p class="card-title h4">Mediendatei hochladen oder auswählen</p>
      </div>
      <div class="card-body flex-grow overflow-y-auto">

        <media-upload/>

        <div class="divider" data-content="UND DANACH AUSWÄHLEN"></div>

        <div v-if="mediaFiles.length > 0" class="grid grid-cols-5 max-w-4xl gap-4 overflow-auto mt-4">

          <!-- youtube selection -->
          <form class="flex justify-center items-center flex-col border-2 border-dashed"
                @submit="$emit('success', youtubeUrl)">
            <font-awesome-icon :icon="['fab', 'youtube']" class="text-7xl" style="color: #ff0000"/>

            <!-- youtube url input -->
            <label class="text-center">
              <span>Video Link</span>
              <input v-model="youtubeUrl" class="form-input" pattern="https:\/\/www.youtube.com\/watch\?v=..........."
                     placeholder="https://www.youtube.com/watch?v=..." required type="url">
            </label>

            <button class="btn" type="submit">Speichern</button>
          </form>

          <media-card v-for="mediaObject in mediaFiles" :object="mediaObject" delete-disabled
                      @click="$emit('success', mediaObject.ref.fullPath)"/>

          <media-card v-if="!audio" d v-for="mediaObject in $store.state.media.publicMediaFiles" :object="mediaObject" delete-disabled
                      @click="$emit('success', mediaObject.ref.fullPath)"/>

        </div>
        <p v-else class="max-w-2xl">Du hast noch keine Mediendateien hochgeladen. Dafür musst du zuerst im Dashboard auf
          die Mediathek
          gehen und dort Dateien hochladen – danach kannst du diese hier auswählen. </p>
      </div>
      <div class="card-footer">
        <button class="btn" @click="$emit('failure')">Abbrechen</button>
      </div>
    </div>
  </PopupContainer>
</template>
<script>
import PopupContainer from "@/components/gui-components/PopupContainer";
import MediaCard from "@/components/dashboard/MediaCard";
import { filteredMediaFiles } from "@/store/media";
import MediaUpload from "@/components/MediaUpload.vue";

export default {
  components: { MediaCard, PopupContainer, MediaUpload },
  props: {
    audio: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      youtubeUrl: ""
    }
  },
  computed: {
    filteredMediaFiles,
    mediaFiles() {
      return this.filteredMediaFiles.filter(mediaFile => {
        if (this.audio)
          return mediaFile.meta.contentType.includes("audio");
        else
          return !mediaFile.meta.contentType.includes("audio");
      });
    }
  }
}
</script>