<template>
  <div class="card" :style="question.fromLibrary && 'border-color: blue !important;'" @click="$emit('click')">
    <div class="card-header">
      <div class="card-title h5">{{ questionID + 1 }} – {{ question.title }}</div>
      <div class="card-subtitle text-gray">{{ question.type }}</div>
    </div>
    <div class="card-body">

      <!-- show options only when type is SLIDE -->
      <ol v-if="question.type !== QUESTION_TYPES.SLIDE" class="float-left list-decimal list-inside">
        <li v-for="(answer, aid) in question.options"
            :class="(aid === question.correct || (question.correct.includes && question.correct.includes(aid))) && question.type === QUESTION_TYPES.KNOWLEDGE && 'text-green-600 font-bold'">
          {{ answer }}
        </li>
      </ol>

      <img v-if="mediaObject && mediaObject.meta.contentType.startsWith('image/')" :src="mediaObject.url" alt="Visual"
           class="w-1/3 float-right"/>
      <video v-if="mediaObject && mediaObject.meta.contentType.startsWith('video/')" :src="mediaObject.url"
             class="bg-black w-1/3 float-right" controls/>

      <!-- youtube -->
      <div v-if="/https:\/\/www.youtube.com\/watch\?v=.........../.exec(question.media)"
           class="w-1/3 float-right text-right text-xs">
        <font-awesome-icon :icon="['fab', 'youtube']" class="text-6xl" style="color: #ff0000"/>
        <p>Für Youtube Videos ist keine Vorschau verfügbar. </p>
      </div>


    </div>
    <div v-if="allowEdit" class="card-footer">
      <div class="btn-group">
        <button class="btn btn-primary" @click="$router.push(`/${organization ? 'o' : 'e'}/${quiz.id}/${questionID}`)">
          Bearbeiten
        </button>
        <button class="btn" @click="deleteQuestion">Löschen</button>
      </div>
    </div>
  </div>
</template>
<script>
import { injectComponentWithReturn } from "@/main";
import { quizCollection } from "@/plugins/firebase";
import firebase from "firebase/app";
import ConfirmPopup, { deleteQuestion } from "../popups/ConfirmPopup";
import { logDBError } from "@/store";
import { QUESTION_TYPES } from "@/constants/questions";
import { organizationCollection } from "../../plugins/firebase";
import { getMediaFileURL } from "@/store/media";

export default {
  props: ["question", "questionID", "quiz", "allowEdit", "organization"],
  methods: {
    deleteQuestion() {
      injectComponentWithReturn(ConfirmPopup, {
        question: deleteQuestion("Frage", true),
        confirm: "Löschen"
      }).then(() => {
        if (this.organization)
          organizationCollection.doc(this.quiz.id).update({
            questions: firebase.firestore.FieldValue.arrayRemove(this.question),
          }).catch(logDBError(this.$store, "Frage konnte nicht gelöscht werden. "));
        else
          quizCollection.doc(this.quiz.id).update({
            questions: firebase.firestore.FieldValue.arrayRemove(this.question),
          }).catch(logDBError(this.$store, "Frage konnte nicht gelöscht werden. "));
      });
    }
  },
  computed: {
    mediaObject() {
      return getMediaFileURL(this.question.media);
    },
  },
  data() {
    return {
      QUESTION_TYPES
    }
  },
}
</script>