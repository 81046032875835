// predefined quiz templates
export const predefinedQuizTemplates = [{
    // to be overwritten
    "creator": "UuS2Q2RiSueVBWDPnAsceJRWcGp2",
    "created": 1705672265404,
    "organization": "3F8QSNCNpdoCVeROUSuU",

    // to stay the same
    "id": "Fa9emGoKU4iJ3DXs4JV8",
    "publicTemplate": true,
    "questions": [
        {
            "title": "Wie alt bist du?",
            "media": "media/19614687-c622-43d1-98ec-6cd502020847-Fragezeichen",
            "correct": 0,
            "fromLibrary": true,
            "id": 49,
            "type": "Erfahrungsfrage",
            "options": [
                "13 Jahre oder jünger",
                "14 - 17 Jahre ",
                "18 - 21 Jahre",
                "22 Jahre oder älter"
            ]
        },
        {
            "fromLibrary": true,
            "id": 50,
            "options": [
                "weiblich",
                "männlich",
                "divers"
            ],
            "media": "media/9d2448b4-a505-4719-ae00-aae9b470f15e-Abwägen_Überlegen",
            "title": "Bist du...?",
            "correct": 0,
            "type": "Erfahrungsfrage"
        },
        {
            "media": "media/5fe9be52-9a60-480d-bfa1-b38f72875b44-Vape_Qualm",
            "options": [
                "Ja",
                "Nein"
            ],
            "id": 52,
            "fromLibrary": true,
            "type": "Erfahrungsfrage",
            "title": "Hast du Cannabis schon mindestens einmal ausprobiert?",
            "correct": 0
        },
        {
            "id": 51,
            "correct": 0,
            "title": "Kennst du Hilfsangebote in deiner Nähe?",
            "media": "media/5a9a2415-2ad9-4598-afda-6887aca4d881-Wegweiser",
            "fromLibrary": true,
            "type": "Erfahrungsfrage",
            "options": [
                "Ja",
                "Nein"
            ]
        },
        {
            "media": "media/221dfa7e-0b5b-406d-9048-7a7083a6f018-Konzert",
            "options": [
                "Ja",
                "Nein"
            ],
            "id": 36,
            "type": "Wissensfrage",
            "title": "Viele Musikstars meinen, dass sie bekifft besser Lieder schreiben oder auf die Bühne gehen können.  Ist das wirklich der Fall?",
            "correct": 1
        },
        {
            "media": "media/221dfa7e-0b5b-406d-9048-7a7083a6f018-Konzert",
            "title": "Bekifft kann man sich schlechter konzentrieren und reagiert langsamer. Man ist auch weniger selbstkritisch und das führt eher dazu, dass die Musik schlechter klingt. ",
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "correct": 0,
            "id": 37
        },
        {
            "media": "media/14a08cf8-e040-4fda-8993-c80c00227565-Cannabis_Produkte2",
            "type": "Wissensfrage",
            "title": "Welche Abkürzung steht für die im Cannabis enthaltene Substanz, die den typischen Cannabisrausch („bekifft sein!“) verursacht?",
            "correct": 1,
            "id": 10,
            "options": [
                "SOS",
                "THC",
                "TBH"
            ]
        },
        {
            "id": 25,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "THC = Tetrahydrocannabinol ist der wichtigste berauschende Wirkstoff der Cannabispflanze. Die Cannabispflanze enthält insgesamt über 400 unterschiedliche Wirkstoffe. TBH – „to be honest“ („um ehrlich zu sein“) ist nicht gemeint und der Notruf SOS auch nicht.",
            "correct": 0,
            "type": "Folie",
            "media": "media/14a08cf8-e040-4fda-8993-c80c00227565-Cannabis_Produkte2"
        },
        {
            "correct": [
                0,
                1
            ],
            "answerType": "Multiple Choice",
            "id": 11,
            "type": "Wissensfrage",
            "media": "media/e12340d1-e6f4-4255-93a9-47b66e1b9168-Körpermodell",
            "title": "Wo im Körper wirkt Cannabis?",
            "options": [
                "Gehirn",
                "Verdauungstrakt",
                "In den Beinen"
            ]
        },
        {
            "id": 13,
            "correct": 0,
            "title": "Im Körper gibt es fast überall Cannabis-Rezeptoren. Die meisten befinden sich im Gehirn, im Verdauungstrakt und im Herz-Kreislaufsystem.  Deshalb hat Cannabis viele verschiedene Wirkungen, z.B. Ess-Anfälle, Vergesslichkeit, aber auch Linderung von Schmerzen. In den Beinen hat Cannabis keine spezielle Wirkung.",
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/e12340d1-e6f4-4255-93a9-47b66e1b9168-Körpermodell"
        },
        {
            "correct": 0,
            "id": 0,
            "type": "Wissensfrage",
            "media": "media/2977e1f5-c3b0-4027-9749-420ae5fbc253-Cannabis_Marihuana",
            "title": "Viele Cannabis-Konsumierende entwickeln auch eine Abhängigkeit von Tabak bzw. Nikotin.",
            "options": [
                "Richtig",
                "Falsch"
            ]
        },
        {
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 15,
            "media": "media/2977e1f5-c3b0-4027-9749-420ae5fbc253-Cannabis_Marihuana",
            "correct": 0,
            "title": "Wer Cannabis als Joint konsumiert, also Cannabis mit Tabak vermischt, kann leicht von Tabak bzw. Zigaretten abhängig werden. Denn: Nikotin hat ein starkes Abhängigkeitspotenzial."
        },
        {
            "answerType": "Multiple Choice",
            "type": "Erfahrungsfrage",
            "options": [
                "Ich spreche ihn ruhig und sachlich auf meine Beobachtungen an.",
                "Ich ärgere mich über sein Verhalten, mache aber gar nichts.",
                "Ich beende unsere Freundschaft, ohne ihn darauf  anzusprechen."
            ],
            "id": 1,
            "media": "media/7efb1464-5398-457d-b089-97815ffdf088-Cannabis_Joint",
            "correct": [
                0,
                1,
                2
            ],
            "title": "Fallbeispiel: Dein Freund Elias kifft in letzter Zeit häufiger, seine Noten sind schlechter geworden und er geht nicht mehr zum Fußballtraining. Was kannst Du tun?"
        },
        {
            "title": "Rede in einem ruhigen Moment mit ihm über Deine Beobachtungen und dass Du Dir Sorgen machst. ",
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 14,
            "media": "media/7efb1464-5398-457d-b089-97815ffdf088-Cannabis_Joint",
            "correct": 0
        },
        {
            "correct": [
                0,
                1,
                3
            ],
            "id": 2,
            "type": "Wissensfrage",
            "answerType": "Multiple Choice",
            "options": [
                "Fürsorgliche Familie",
                "Gutes Selbstwertgefühl",
                "Attraktives Äußeres",
                "Ausfüllende Hobbys"
            ],
            "media": "media/52b84363-b121-43f8-ab65-33727ede7393-Frauen und Kaugummi",
            "title": "Es gibt Schutzfaktoren, die einen riskanten Cannabiskonsum unwahrscheinlicher machen. Welche gehören dazu?"
        },
        {
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/52b84363-b121-43f8-ab65-33727ede7393-Frauen und Kaugummi",
            "type": "Folie",
            "id": 12,
            "title": "Eine liebevolle und fürsorgliche Familie, ausfüllende Hobbys und ein gutes Selbstwertgefühl können Dich vor einer Suchterkrankung schützen. Ein attraktives Äußeres dagegen nicht."
        },
        {
            "id": 3,
            "answerType": "Multiple Choice",
            "options": [
                "Jugendamt - oder Suchtberatungsstellen",
                "Ordnungsamt",
                "Beratungslehrkraft oder Schulsozialarbeit",
                "Eltern, Geschwister, gute Freund:innen"
            ],
            "correct": [
                0,
                2,
                3
            ],
            "media": "media/0af06136-d8af-4e40-86ac-69c0e906979c-Cannabis_Joint auf Tisch2",
            "title": "Wer kann Dir helfen, wenn der Cannabiskonsum zu viel wird und Du oder Dir nahestehende Personen Unterstützung benötigen?",
            "type": "Wissensfrage"
        },
        {
            "correct": 0,
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/0af06136-d8af-4e40-86ac-69c0e906979c-Cannabis_Joint auf Tisch2",
            "id": 4,
            "title": "Stimmt, es gibt sogar mehrere Möglichkeiten.  Wende Dich z.B. an eine Person, der Du vertraust – Familie, Freundeskreis, in der Schule oder einer Beratungsstelle. Berichte von Deinen Beobachtungen und nimm Unterstützung an. Das Ordnungsamt ist dabei nicht hilfreich."
        },
        {
            "media": "media/330da965-76ac-4edb-952e-3253e59babb2-Cannabis_Joint teilen",
            "type": "Wissensfrage",
            "correct": 0,
            "title": "Welche Konsumform von Cannabis ist am weitesten verbreitet?",
            "options": [
                "Rauchen (Joint, Bong, Pfeife)",
                "Intravenös mit der Spritze in die Armvene",
                "Essen (Kekse, Kuchen)"
            ],
            "id": 5
        },
        {
            "type": "Folie",
            "id": 16,
            "correct": 0,
            "media": "media/330da965-76ac-4edb-952e-3253e59babb2-Cannabis_Joint teilen",
            "title": "Cannabis wird am häufigsten geraucht bzw. inhaliert.  Es kann auch gegessen oder getrunken werden. Zum intravenösen Spritzen ist es ungeeignet. ",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ]
        },
        {
            "id": 6,
            "title": "Abhängigkeit von Cannabis erkennt man an typischen Entzugserscheinungen (Symptomen). Welches Symptom gehört nicht dazu?",
            "media": "media/b65917dc-948f-4be3-ada2-8cb3ed68a93a-Cannabis_Marihuana auf Tisch",
            "correct": 1,
            "options": [
                "Konzentrationsstörungen und Unruhe",
                "Lachanfälle",
                "(Ein-)Schlafschwierigkeiten"
            ],
            "type": "Wissensfrage"
        },
        {
            "media": "media/b65917dc-948f-4be3-ada2-8cb3ed68a93a-Cannabis_Marihuana auf Tisch",
            "type": "Folie",
            "correct": 0,
            "title": "Plötzliche Lachanfälle sind keine typischen Entzugserscheinungen.  Häufig treten Unruhe und Konzentrationsstörungen sowie Schlafstörungen auf.  Diese Symptome sind nicht sehr lustig.  ",
            "id": 17,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ]
        },
        {
            "title": "Sind Joints schädlicher als Zigaretten?",
            "type": "Wissensfrage",
            "options": [
                "Joints haben weniger Nebenwirkungen als Zigaretten.",
                "So ein Joint ist \"voll Bio\" und damit eher gesund.",
                "So wie Joints geraucht werden, sind sie schädlicher."
            ],
            "id": 18,
            "correct": 2,
            "media": "media/fa3da7f4-f4f9-440d-8f40-101303db7317-Vape_Qualm"
        },
        {
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "type": "Folie",
            "media": "media/fa3da7f4-f4f9-440d-8f40-101303db7317-Vape_Qualm",
            "title": "Beides ist natürlich schädlich! Da beim Kiffen in der Regel tiefer inhaliert und der Rauch länger in der Lunge gehalten wird, sind Joints schädlicher als Zigaretten!",
            "correct": 0,
            "id": 19
        },
        {
            "type": "Wissensfrage",
            "title": "Welche Wirkung hat der Konsum von Cannabis auf das Gehirn?",
            "correct": 1,
            "options": [
                "Cannabis fördert die Konzentration.",
                "Cannabis führt zu Antriebslosigkeit.",
                "Cannabis macht wach und fit."
            ],
            "media": "media/0dcabeca-cc14-43f7-a0b9-c1269fff6418-Gehirn",
            "id": 20
        },
        {
            "title": "Menschen, die regelmäßig und häufig konsumieren, berichten davon, dass sie sich antriebslos fühlen. Sie können die kreativen Ideen, die im Rausch entstehen, im nüchternen Zustand oft nicht umsetzen.  Wie stark die Wirkung ist, hängt von der Dosis, der psychischen Verfassung der Person und der Situation ab.",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 21,
            "type": "Folie",
            "media": "media/0dcabeca-cc14-43f7-a0b9-c1269fff6418-Gehirn",
            "correct": 0
        },
        {
            "id": 8,
            "title": "Welche der folgenden Krankheiten wird mit Cannabis als Medizin behandelt?",
            "correct": 1,
            "media": "media/eeb8459f-053d-49d5-a042-932213857e1d-Cannabis_Pflanze",
            "type": "Wissensfrage",
            "options": [
                "Bronchitis",
                "Tourette-Syndrom",
                "Reizdarm"
            ]
        },
        {
            "title": "Das Tourette-Syndrom, auch als „Tic“ bekannt, kann mit Medizinalcannabis behandelt werden. Bei Bronchitis und Reizdarm hingegen erfolgt keine ärztliche Verschreibung von Cannabis als Medizin. Insgesamt gibt es wenige wissenschaftliche Studien zur medizinischen Wirkung von Cannabis.",
            "id": 22,
            "type": "Folie",
            "correct": 0,
            "media": "media/eeb8459f-053d-49d5-a042-932213857e1d-Cannabis_Pflanze",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ]
        },
        {
            "title": "Cannabis wird auf dem Schwarzmarkt manchmal mit Zusätzen wie Quarzsand, Blei oder gemahlenem Glas gestreckt (vermischt). Warum?",
            "type": "Wissensfrage",
            "media": "media/aa55d9c5-e314-400a-b4b4-492638ecfa97-Cannabis_Dealer",
            "correct": 2,
            "id": 23,
            "options": [
                "Um den zu süßen Geschmack zu überdecken.",
                "Dadurch wird die Farbe stärker und es sieht besser aus.",
                "Die Zusätze erhöhen das Gewicht und ermöglichen dadurch zusätzliche Gewinne."
            ]
        },
        {
            "id": 24,
            "media": "media/aa55d9c5-e314-400a-b4b4-492638ecfa97-Cannabis_Dealer",
            "correct": 0,
            "title": "Ziel ist es, das Gewicht zu erhöhen, um damit größere Gewinne zu erzielen. ",
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ]
        },
        {
            "correct": 0,
            "media": "media/f75f137d-8894-48db-be73-ced9b936b5ef-Urintest",
            "title": "Wie lange kann Cannabis bei regelmäßigem Konsum im Urin nachgewiesen werden?",
            "id": 26,
            "type": "Wissensfrage",
            "options": [
                "Bis zu 3 Monaten",
                "Bis zu einem Jahr",
                "Bis zu einer Woche"
            ]
        },
        {
            "media": "media/f75f137d-8894-48db-be73-ced9b936b5ef-Urintest",
            "title": "Bei regelmäßigem Konsum ist Cannabis bis zu 3 Monate im Urin nachweisbar. In den Haaren (alle Körperhaare) ist der Konsum verschiedener Substanzen praktisch zeitlich unbegrenzt nachweisbar.",
            "correct": 0,
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 27
        },
        {
            "type": "Wissensfrage",
            "title": "Behauptung: „Alle kiffen doch!“",
            "options": [
                "Richtig",
                "Falsch"
            ],
            "correct": 1,
            "id": 28,
            "media": "media/ca96f382-62a0-4237-a1af-cde28fb3e163-Umfrage"
        },
        {
            "title": "Nur knapp 10 Prozent der Jugendlichen zwischen 12 und 17 Jahren haben schon mal gekifft. Bei den jungen Erwachsenen zwischen 18 und 25 Jahren haben etwa 50 Prozent schon mal probiert. Nur knapp 9 Prozent von ihnen konsumieren regelmäßig.",
            "media": "media/ca96f382-62a0-4237-a1af-cde28fb3e163-Umfrage",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 29,
            "type": "Folie",
            "correct": 0
        },
        {
            "type": "Wissensfrage",
            "id": 30,
            "correct": 2,
            "title": "Was bedeutet der Code 420?",
            "options": [
                "Er benennt die Anzahl der Wirkstoffe in der Cannabispflanze.",
                "420 Tage Gefängnis für Dealer, wenn sie vor Gericht verurteilt werden.",
                "Er ist das Erkennungsmerkmal in der Cannabis-Szene."
            ]
        },
        {
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "type": "Folie",
            "title": "420 – ausgesprochen „for-twenty“ ist ein Code, der ursprünglich aus den USA stammt. Eine Gruppe Jugendlicher traf sich nach dem Unterricht um  4 Uhr 20 p.m. nachmittags zum Kiffen. Die Hippie-Band Grateful Dead schnappte den Code auf, der sich über die Jahre weltweit verbreitete.",
            "id": 31,
            "correct": 0
        },
        {
            "media": "media/39259633-0809-4705-bfa6-8819604f177f-EKG",
            "type": "Wissensfrage",
            "id": 38,
            "options": [
                "Ja",
                "Nein"
            ],
            "title": "Kann eine Überdosis Cannabis zum Tod führen?",
            "correct": 1
        },
        {
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 39,
            "media": "media/39259633-0809-4705-bfa6-8819604f177f-EKG",
            "type": "Folie",
            "title": "Bis heute gibt es keinen bekannten Todesfall durch eine Überdosis. Aber Vorsicht: Bei der Arbeit z.B. an Maschinen und im Straßenverkehr sind tödliche Unfälle unter Cannabiseinfluss bekannt. Außerdem führten synthetisch hergestellte hochpotente Cannabinoide bereits zu Todesfällen. ",
            "correct": 0
        },
        {
            "id": 40,
            "media": "media/a0edcd1f-2190-487a-bce9-689364e0d969-Lernen",
            "options": [
                "Richtig",
                "Falsch"
            ],
            "type": "Wissensfrage",
            "correct": 1,
            "title": "Cannabiskonsum entspannt und durch weniger Stress gelingt das Lernen besser."
        },
        {
            "type": "Folie",
            "media": "media/a0edcd1f-2190-487a-bce9-689364e0d969-Lernen",
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "Cannabiskonsum ist zum Lernen ungeeignet. Es fällt schwer, sich zu konzentrieren. Beim Lernen und bei Prüfungen fallen die Ergebnisse meistens deutlich schlechter aus. ",
            "id": 41
        },
        {
            "options": [
                "bekommt Probleme mit seiner Krankenkasse.",
                "muss zur Ausnüchterung in die Zelle.",
                "muss mit dem Verlust des Führerscheins rechnen."
            ],
            "title": "Wer bekifft ein Fahrzeug fährt und erwischt wird,",
            "media": "media/08402302-2419-42e1-928b-7ca50069b025-Führerschein",
            "id": 42,
            "correct": 2,
            "type": "Wissensfrage"
        },
        {
            "type": "Folie",
            "title": "Die Wirkung von THC verlangsamt die Reaktionsfähigkeit und macht das bekiffte Fahren zu einem Risiko für alle Beteiligten. Der Erwerb oder Besitz des Führerscheins können durch Cannabiskonsum in Gefahr sein. Wer Cannabis konsumiert, darf danach nicht am Straßenverkehr teilnehmen – weder mit dem Auto, auf dem Rad noch auf dem E-Roller. ",
            "id": 43,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/08402302-2419-42e1-928b-7ca50069b025-Führerschein",
            "correct": 0
        },
        {
            "type": "Wissensfrage",
            "title": "Cannabisrauchen kann verschiedene körperliche und psychische Effekte auslösen. Welche der hier genannten Effekte gehören dazu?",
            "id": 53,
            "options": [
                "Heißhunger",
                "Extreme Wachheit",
                "Trockener Mund"
            ],
            "correct": [
                2,
                0
            ],
            "answerType": "Multiple Choice",
            "media": "media/5d286bd7-1ff3-4fe7-b158-e382842a872a-Heißhunger"
        },
        {
            "type": "Folie",
            "media": "media/5d286bd7-1ff3-4fe7-b158-e382842a872a-Heißhunger",
            "correct": 0,
            "title": "Extreme Wachheit ist falsch. Aufmerksamkeit, Wachheit und Konzentration werden im Cannabisrausch eher herabgesetzt. Heißhunger und trockener Mund sind typisch beim Konsum.",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 55
        },
        {
            "media": "media/1ac91a55-71b2-4a49-a228-81290bfbb876-Lachende Frauen",
            "options": [
                "Gerötete Augen",
                "Grinsen, vermehrtes Lachen",
                "Häufige Toilettengänge"
            ],
            "type": "Wissensfrage",
            "id": 56,
            "title": "Woran kann man erkennen, ob ein Mensch Cannabis konsumiert hat? Welche Wirkungen werden oft beobachtet?",
            "answerType": "Multiple Choice",
            "correct": [
                0,
                1
            ]
        },
        {
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 57,
            "type": "Folie",
            "title": "Gerötete Augen und vermehrtes Grinsen und Lachen sind häufige Wirkungen von Cannabis.  Häufige Toilettengänge gehören nicht dazu. ",
            "media": "media/1ac91a55-71b2-4a49-a228-81290bfbb876-Lachende Frauen",
            "correct": 0
        },
        {
            "id": 58,
            "title": "Es gibt viele Wörter für den Cannabis-Rausch. Welches der folgenden Wörter gehört nicht dazu?",
            "correct": 1,
            "media": "media/1a8832d6-27dd-47b8-847e-a1e4f0d1b15d-Daumen runter",
            "type": "Wissensfrage",
            "options": [
                "Stoned",
                "Buttered",
                "Breit"
            ]
        },
        {
            "correct": 0,
            "type": "Folie",
            "media": "media/1a8832d6-27dd-47b8-847e-a1e4f0d1b15d-Daumen runter",
            "id": 59,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "Das englische „buttered“ heißt im Deutschen „gebuttert“  und wird für Brot oder andere Speisen benutzt. Hat also mit einem Cannabis-Rausch nichts zu tun. Die Begriffe „stoned“ und „breit“ beschreiben Rauschzustände."
        },
        {
            "options": [
                "Verdampfen",
                "Kiffen",
                "Essen in Form von Keksen"
            ],
            "correct": 0,
            "type": "Wissensfrage",
            "media": "media/dc2f40a8-0e5a-441b-829a-2026d1102f8e-Nachdenklich",
            "title": "Jede Form von Substanzkonsum ist riskant.  Welcher Cannabiskonsum ist eher risikoarm?",
            "id": 60
        },
        {
            "media": "media/dc2f40a8-0e5a-441b-829a-2026d1102f8e-Nachdenklich",
            "id": 61,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "Eine eher risikoarme Konsumform ist das Verdampfen.  Beim Rauchen entstehen durch das Verbrennen giftige Stoffe. Ist Tabak im Joint enthalten, kommt außerdem noch die Wirkung von Nikotin hinzu. Bei Cannabis-Keksen setzt die Wirkung mit Verzögerung ein und hält länger an.",
            "correct": 0,
            "type": "Folie"
        },
        {
            "correct": 0,
            "id": 62,
            "type": "Wissensfrage",
            "media": "media/de8003bd-72e9-4d86-8bee-35922988047a-google",
            "options": [
                "Kiffen kommt von „kayf“, einem arabischen Wort (ausgesprochen „kif“) und bedeutet „Wohlbefinden“.",
                "Kaffee und „kiffen“ haben denselben Wortstamm.",
                "Kiefern und Hanf sind verwandte Pflanzenarten."
            ],
            "title": "Woher kommt das Wort „kiffen“?"
        },
        {
            "id": 63,
            "type": "Folie",
            "title": "„kayf“ (Aussprache „kīf“) ist richtig und bedeutet „Wohlbefinden“. Es wird auch als Wort für „Haschisch“ benutzt. Kaffee und Kiefern haben mit „kiffen“ nichts zu tun.",
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/de8003bd-72e9-4d86-8bee-35922988047a-google"
        },
        {
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/be6db7c4-46b8-458c-88b6-d5afe4675eb7-QR-Code Stark statt breit",
            "type": "Folie",
            "id": 48,
            "title": "Du hast weitere Fragen zu Cannabis? Dann informiere Dich gerne über die Internetseite www.starkstattbreit.nrw.de weiter."
        }
    ],
    "name": "Das Cannabis-Quiz",
    "description": "Dieses Quiz wurde durch die Landesfachstelle Prävention der Suchtkooperation NRW erstellt. Grundlagenteil bildet dieses Quiz ist das Kiffer-Quiz (Stand: 2018) der Drogenhilfe Köln Projekt gGmbH Fachstelle für Suchtprävention. Stand: Frühjahr 2024, 2. überarbeitete Version Rückfragen: Landesfachstelle Prävention NRW - www.ginko-stiftung.de /www.starkstattbreit.nrw.de"
}, {
    // to be overwritten
    "created": 1705671358051,
    "creator": "UuS2Q2RiSueVBWDPnAsceJRWcGp2",
    "organization": "3F8QSNCNpdoCVeROUSuU",

    // to stay the same
    "id": "2M4roXZakiePvXKpUWYW",
    "name": "Vapes, Nikotin & Co",
    "description": 'Dieses Quiz wurde mit freundlicher Genehmigung von update Fachstelle für Suchtprävention - Kinder-, Jugend- und Elternberatung Caritas / Diakonie, www.suchthilfe-bonn.de, durch die Landesfachstelle Prävention der Suchtkooperation NRW erstellt. \n\nStand: Frühjahr 2024\n\nRückfragen: Landesfachstelle Prävention NRW - www.ginko-stiftung.de / www.loq.nrw.de\n',
    "publicTemplate": true,
    "questions": [
        {
            "media": "media/19614687-c622-43d1-98ec-6cd502020847-Fragezeichen",
            "fromLibrary": true,
            "options": [
                "13 Jahre oder jünger",
                "14 - 17 Jahre ",
                "18 - 21 Jahre",
                "22 Jahre oder älter"
            ],
            "title": "Wie alt bist du?",
            "type": "Erfahrungsfrage",
            "correct": 0,
            "id": 20
        },
        {
            "id": 21,
            "title": "Bist du...?",
            "type": "Erfahrungsfrage",
            "fromLibrary": true,
            "media": "media/9d2448b4-a505-4719-ae00-aae9b470f15e-Abwägen_Überlegen",
            "correct": 0,
            "options": [
                "weiblich",
                "männlich",
                "divers"
            ]
        },
        {
            "id": 23,
            "fromLibrary": true,
            "title": "Hast du Vapes schon mindestens einmal ausprobiert?",
            "media": "media/87c0ac0c-e64d-4a01-9477-3fa898768cd1-Vape_lifestyle_bunt",
            "answerType": "Multiple Choice",
            "options": [
                "Ja",
                "Nein"
            ],
            "correct": [
                0,
                1
            ],
            "type": "Erfahrungsfrage"
        },
        {
            "media": "media/5a9a2415-2ad9-4598-afda-6887aca4d881-Wegweiser",
            "correct": 0,
            "options": [
                "Ja",
                "Nein"
            ],
            "id": 22,
            "fromLibrary": true,
            "type": "Erfahrungsfrage",
            "title": "Kennst du Hilfsangebote in deiner Nähe?"
        },
        {
            "correct": 2,
            "id": 0,
            "title": "Zu den möglichen Folgen des Vapings zählen...",
            "type": "Wissensfrage",
            "media": "media/7545641a-27b4-47d2-b33e-40fa6cd802c1-Vape_Vape im Qualm",
            "options": [
                "Haarausfall",
                "Halluzinationen",
                "Atemwegsreizungen",
                "Brüchige Fußnägel"
            ]
        },
        {
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "type": "Folie",
            "title": "...andere mögliche Risiken sind zum Beispiel: Allergische Reaktionen, Nikotinabhängigkeit, Reizung der Nasenschleimhäute.",
            "media": "media/7545641a-27b4-47d2-b33e-40fa6cd802c1-Vape_Vape im Qualm",
            "id": 9
        },
        {
            "title": "Ab wie vielen Jahren darf man Vapes kaufen?",
            "media": "media/9af0b4b9-9b6b-4c32-9bda-130d73311f12-Vape_Vapes auf Tisch",
            "type": "Wissensfrage",
            "correct": 1,
            "id": 2,
            "options": [
                "Vapes sind in Deutschland verboten.",
                "Ab 18 Jahren",
                "Ab 16 Jahren"
            ]
        },
        {
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "... das gilt auch für nikotinfreie E-Zigaretten und Shishas. ",
            "correct": 0,
            "id": 10,
            "media": "media/9af0b4b9-9b6b-4c32-9bda-130d73311f12-Vape_Vapes auf Tisch",
            "type": "Folie"
        },
        {
            "correct": 1,
            "options": [
                "Zwölfbar",
                "Elfbar",
                "Barfuß",
                "Elfenkönig"
            ],
            "id": 1,
            "title": "Wie heißt eine bekannte Marke für Vapes in Deutschland?",
            "media": "media/87c0ac0c-e64d-4a01-9477-3fa898768cd1-Vape_lifestyle_bunt",
            "type": "Wissensfrage"
        },
        {
            "id": 3,
            "options": [
                "40 mg",
                "20 mg",
                "1 mg"
            ],
            "media": "media/9a31db62-1aad-4809-88c6-a69d09139c5f-Vape_Material Vape",
            "title": "Wie viel Nikotin ist in einer Vape?",
            "type": "Wissensfrage",
            "correct": 0
        },
        {
            "type": "Folie",
            "media": "media/9a31db62-1aad-4809-88c6-a69d09139c5f-Vape_Material Vape",
            "correct": 0,
            "title": "Eine handelsübliche Vape enthält 2 ml Liquid mit der maximal erlaubte Menge von 20mg Nikotin pro Milliliter Liquid - also 40mg Nikotin. Das entspricht etwa dem Nikotin, das beim Rauchen von 2 - 4 Schachteln Zigaretten aufgenommen wird. ",
            "id": 11,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ]
        },
        {
            "media": "media/e66a620d-90fc-43e1-8fe1-f2e018b8eefb-Vape_Verdampfer",
            "options": [
                "ebenfalls verbrannt. ",
                "gedampft. ",
                "geschmolzen.",
                "eingefroren. "
            ],
            "correct": 1,
            "id": 4,
            "title": "Zigaretten werden verbrannt und das Liquid in Vapes wird ... ?",
            "type": "Wissensfrage"
        },
        {
            "correct": 3,
            "title": "Was ist KEIN Bestandteil einer Vape? ",
            "options": [
                "Mundstück",
                "Lithium-Ionen-Akku",
                "Tank",
                "Kühlflüssigkeit"
            ],
            "id": 5,
            "media": "media/06050965-d174-4d4c-b706-9e5613fad7ca-Vape_beim Kaffee",
            "type": "Wissensfrage"
        },
        {
            "id": 16,
            "media": "media/fa3da7f4-f4f9-440d-8f40-101303db7317-Vape_Qualm",
            "correct": 2,
            "options": [
                "Schwangere und Stillende",
                "Senior:innen über 65 Jahren",
                "Jugendliche und junge Erwachsene"
            ],
            "type": "Wissensfrage",
            "title": " Welche Zielgruppe wird mit Vapeprodukten angesprochen?"
        },
        {
            "media": "media/986e004d-4c00-4ff9-b3c6-d6fd6d6c7aa4-Vape_Frau raucht",
            "correct": 2,
            "id": 7,
            "options": [
                "Restmüll",
                "Gelbe Tonne",
                "Elektrokleinmüll"
            ],
            "type": "Wissensfrage",
            "title": "Wo wird eine Vape richtig entsorgt? "
        },
        {
            "title": "Vapes sind Elektrokleinmüll und enthalten wertvolle Rohstoffe! Sie können zb. in Supermärkten und Discountern, im Fachhandel oder bei öffentlichen Sammelstellen entsorgt und dem Recycling zugeführt werden. Landen sie im öffentlichen Raum (z.B. in Parks und Grünanlagen), können sie erheblichen Schaden anrichten.  ",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "type": "Folie",
            "correct": 0,
            "id": 12,
            "media": "media/986e004d-4c00-4ff9-b3c6-d6fd6d6c7aa4-Vape_Frau raucht"
        },
        {
            "id": 6,
            "media": "media/99bb1a37-5c97-4255-9717-c9cd15ea41cd-Vape_Frau vaped",
            "title": "Schätzfrage Akku-Kapazität: Wie viele Vapes entsprechen einem neuwertigen Handyakku?",
            "correct": 0,
            "type": "Wissensfrage",
            "options": [
                "ca. 10",
                "ca. 100 ",
                "ca. 200",
                "ca. 500"
            ]
        },
        {
            "type": "Wissensfrage",
            "options": [
                "Jugendschutzgesetz",
                "Vape-Gesetz",
                "Konsumkontrollgesetz"
            ],
            "title": " Wie heißt das Gesetz, das eine Altersbegrenzung zum Kauf von Vapes vorgibt? ",
            "correct": 0,
            "media": "media/55e34926-d803-4115-9e1d-036c6f1fd9c3-Plenarsaal ",
            "id": 18
        },
        {
            "id": 15,
            "type": "Wissensfrage",
            "options": [
                "Rasenmäher",
                "Nebelmaschine",
                "Smartphone"
            ],
            "title": "Womit kann man die Technik und Funktionsweise einer Vape vergleichen?",
            "media": "media/e63e9c8b-ce7d-498b-a107-4fa997133de1-Vape_Liquid",
            "correct": 1
        },
        {
            "title": "Wo wurden mehr gesundheitsschädliche Substanzen gefunden? ",
            "options": [
                "Zigarettenrauch",
                "Vaperauch"
            ],
            "correct": 0,
            "media": "media/16945b47-dcf0-42ee-ab02-f53f777921cd-Vape_rauchen",
            "id": 8,
            "type": "Wissensfrage"
        },
        {
            "media": "media/16945b47-dcf0-42ee-ab02-f53f777921cd-Vape_rauchen",
            "title": "Allerdings: Auch im Vape-Dampf wurden schädliche und krebserregende Stoffe gefunden. Bislang liegen außerdem noch keine Langzeitstudien vor. ",
            "id": 13,
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "type": "Folie"
        },
        {
            "id": 17,
            "options": [
                "1 - 3 Euro",
                "9 - 11 Euro",
                "20 - 23 Euro"
            ],
            "type": "Wissensfrage",
            "correct": 1,
            "media": "media/204b21b1-993d-4148-9034-4cf337c5e57b-Vape Kiosk",
            "title": "Wie viel kostet eine Einweg-Vape im Geschäft?"
        },
        {
            "correct": 0,
            "title": "Machen Vapes abhängig?",
            "options": [
                "Ja, weil sie das Nervengift Nikotin enthalten. ",
                "Nein, weil sie ja nur gedampft werden. "
            ],
            "type": "Wissensfrage",
            "id": 14,
            "media": "media/5fe9be52-9a60-480d-bfa1-b38f72875b44-Vape_Qualm"
        },
        {
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/5fe9be52-9a60-480d-bfa1-b38f72875b44-Vape_Qualm",
            "title": "Wichtig: Auch eingeübte Gewohnheiten und Rituale spielen eine Rolle! ",
            "correct": 0,
            "id": 19
        }
    ],
}, {
    // to be overwritten
    "created": 1705482515671,
    "creator": "UuS2Q2RiSueVBWDPnAsceJRWcGp2",
    "organization": "3F8QSNCNpdoCVeROUSuU",

    // to stay the same
    "id": "zZ80sDGreyjtw262FC2k",
    "name": "Das Gam(bl)ing-Quiz",
    "questions": [
        {
            "media": "media/19614687-c622-43d1-98ec-6cd502020847-Fragezeichen",
            "options": [
                "13 Jahre oder jünger",
                "14 - 17 Jahre ",
                "18 - 21 Jahre",
                "22 Jahre oder älter"
            ],
            "correct": 0,
            "title": "Wie alt bist du?",
            "fromLibrary": true,
            "type": "Erfahrungsfrage",
            "id": 37
        },
        {
            "correct": 0,
            "title": "Bist du...?",
            "options": [
                "weiblich",
                "männlich",
                "divers"
            ],
            "media": "media/9d2448b4-a505-4719-ae00-aae9b470f15e-Abwägen_Überlegen",
            "fromLibrary": true,
            "type": "Erfahrungsfrage",
            "id": 38
        },
        {
            "title": "Hast du schon mindestens einmal Online-Games gespielt?",
            "media": "media/7d462887-ba03-440d-b45b-a4d2efbf1eaf-Switch_SuperMario",
            "options": [
                "Ja",
                "Nein"
            ],
            "correct": 0,
            "id": 41,
            "type": "Erfahrungsfrage",
            "answerType": "Single Choice",
            "fromLibrary": true
        },
        {
            "type": "Erfahrungsfrage",
            "correct": 0,
            "fromLibrary": true,
            "media": "media/902033b5-de64-4911-aec2-2226cb9188ae-Casino-Stream",
            "options": [
                "Ja",
                "Nein"
            ],
            "answerType": "Single Choice",
            "title": "Hast du schon mindestens einmal Glücksspiel gespielt?",
            "id": 40
        },
        {
            "media": "media/5a9a2415-2ad9-4598-afda-6887aca4d881-Wegweiser",
            "options": [
                "Ja",
                "Nein"
            ],
            "id": 39,
            "correct": 0,
            "fromLibrary": true,
            "title": "Kennst du Hilfsangebote in deiner Nähe?",
            "type": "Erfahrungsfrage"
        },
        {
            "answerType": "Multiple Choice",
            "id": 0,
            "options": [
                "Bei Glücksspielen kann man Geld gewinnen, bei Videospielen nicht",
                "Wie das Glücksspiel ausgeht, entscheidet der Zufall",
                "Um Glücksspiele zu spielen, muss zuerst Geld eingezahlt werden"
            ],
            "media": "media/7d462887-ba03-440d-b45b-a4d2efbf1eaf-Switch_SuperMario",
            "title": "Welche Merkmale unterscheiden Glücksspiele von Videospielen (wie z.B. Super Mario Land oder World of Warcraft)?",
            "correct": [
                0,
                1,
                2
            ],
            "type": "Wissensfrage"
        },
        {
            "media": "media/0d1a2dc6-580c-4603-bc82-c53f81a06f5c-Switch_Fortnite",
            "id": 1,
            "title": "Im beliebten Ego-Shooter-Spiel Fortnite kann man mittels Ingame-Währung kosmetische Änderungen, Gegenstände und Spielertänze erwerben. Wie heißt die verwendete Ingame-Werbung?",
            "options": [
                "V-Bucks",
                "F-Dollar",
                "Gold-Coin"
            ],
            "type": "Wissensfrage",
            "correct": 0
        },
        {
            "media": "media/e2ab66c4-aee6-4559-ba0d-bdc840c8f01c-Glücksspiel_Lootbox",
            "id": 2,
            "type": "Wissensfrage",
            "correct": 0,
            "title": "Lootboxen sind virtuelle Aufbewahrungsboxen, die in Videospielen zu finden sind und zufällig ausgewählte virtuelle Gegenstände, wie z.B. Waffen, Skins oder Rüstungen enthalten. Es gibt kostenfreie und kostenpflichtige Lootboxen. ",
            "options": [
                "Richtig",
                "Falsch"
            ]
        },
        {
            "correct": [
                0,
                1,
                2
            ],
            "media": "media/9d2448b4-a505-4719-ae00-aae9b470f15e-Abwägen_Überlegen",
            "options": [
                "Ja",
                "Nein",
                "Ich bin mir unsicher"
            ],
            "title": "Was meinst du? Sind Lootboxen Glücksspiel?",
            "id": 3,
            "type": "Erfahrungsfrage",
            "answerType": "Multiple Choice"
        },
        {
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "type": "Folie",
            "title": "Laut Definition sind Lootboxen in Deutschland nicht als Glücksspiel zu bezeichnen, da ein zentrales Glücksspiel-Merkmal fehlt: Die Möglichkeit, Geld zu gewinnen. Bei Lootboxen erhalten Spieler:innen Vorteile im Spiel, Skins oder Rüstungen, kein Geld. Somit gehören Lootboxen zu simuliertem Glücksspiel. ",
            "id": 35,
            "media": "media/7cc9efce-d4ed-4983-a03e-84ec59ddaed9-Info",
            "correct": 0
        },
        {
            "correct": 1,
            "id": 4,
            "type": "Wissensfrage",
            "media": "media/3707368c-e5dc-43d4-8153-af2f80c56ca1-Glücksspiel_virtuelles Geld",
            "options": [
                "Ich kann automatisch den Gewinn des Spiels kaufen.",
                "Ich kann mir durch einen Kauf einen Vorteil im Spiel verschaffen.",
                "Ich kann das Spiel nur spielen, wenn ich Geld einzahle.",
                "Wenn ich das Spiel gewinne, bekomme ich Geld."
            ],
            "title": "Was versteht man unter der Bezeichnung „pay to win“?"
        },
        {
            "options": [],
            "id": 5,
            "type": "Folie",
            "correct": 0,
            "title": "Free-to-play ist ein Geschäftsmodell, bei dem ein Spiel (für Computer, Smartphone o Ä.) kostenfrei erworben und gespielt werden kann. Pay-to-win ist ein Prinzip, bei dem sich Spieler:innen bei einem Spiel Geld einsetzen, um einen Fortschritt oder Vorteile im Spiel zu erhalten. Oft findet man dieses Prinzip bei Free-to-play-Spielen. ",
            "media": "media/7cc9efce-d4ed-4983-a03e-84ec59ddaed9-Info"
        },
        {
            "title": "Womit kann ich spielen?",
            "options": [
                "Konsole",
                "PC",
                "Tablet",
                "Smartphone",
                "Brettspiel"
            ],
            "answerType": "Multiple Choice",
            "id": 6,
            "correct": [
                0,
                1,
                2,
                3,
                4
            ],
            "type": "Wissensfrage",
            "media": "media/08f7288c-8cde-4718-ae6b-9d602ab3d16c-Kontroller"
        },
        {
            "correct": [
                2,
                1,
                0
            ],
            "media": "media/1a8832d6-27dd-47b8-847e-a1e4f0d1b15d-Daumen runter",
            "answerType": "Multiple Choice",
            "id": 7,
            "title": "Wieso wird der Einsatz von Lootboxen in Games regelmäßig kritisiert?",
            "options": [
                "Lootboxen erfüllen glücksspielähnliche Funktionen.",
                "Spieler:innen, die kein Geld für Lootboxen ausgeben möchten oder können, fühlen sich oft benachteiligt.",
                "Spieler:innen wissen vor dem Kauf nicht, was die Lootboxen enthalten."
            ],
            "type": "Wissensfrage"
        },
        {
            "correct": [
                0,
                1,
                2,
                3
            ],
            "answerType": "Multiple Choice",
            "type": "Erfahrungsfrage",
            "title": "Sollten bekannte Sportler:innen oder Influencer:innen Werbung für Glücksspiele machen dürfen? ",
            "media": "media/396eb9da-9678-40a8-b1b9-a14353c97123-Glücksspiel_Fußball",
            "options": [
                "Ja",
                "Nein",
                "Egal",
                "Ja, aber nur für einen Anbieter"
            ],
            "id": 8
        },
        {
            "media": "media/b2f5b314-0336-489d-b454-10b6aa8f0c0a-Werbung",
            "answerType": "Multiple Choice",
            "correct": [
                0,
                1,
                2,
                3,
                4,
                5,
                6
            ],
            "options": [
                "Coin Master",
                "Fortnite",
                "Call of Duty (CoD)",
                "Grand Theft Auto (GTA)",
                "Hogwarts Legacy",
                "FIFA",
                "Clash of Clans"
            ],
            "title": "Zu welchen der folgenden Spiele ist dir in letzter Zeit Werbung aufgefallen?",
            "type": "Erfahrungsfrage",
            "id": 9
        },
        {
            "options": [
                "Ja",
                "Nein",
                "Ich bin mir nicht sicher"
            ],
            "title": "Marcel Eris alias Montana Black ist als Casino-Streamer bekannt. Er streamt live auf Portalen wie Twitch, wie er Online-Casinospiele spielt und schließt dazu Verträge mit Anbietern ab. Was meinst du, ist das Werbung für Glücksspiel?",
            "correct": [
                0,
                1,
                2
            ],
            "media": "media/902033b5-de64-4911-aec2-2226cb9188ae-Casino-Stream",
            "id": 10,
            "answerType": "Multiple Choice",
            "type": "Erfahrungsfrage"
        },
        {
            "media": "media/fe441e2e-7903-4d8a-bb23-522ee0ffafa5-Glücksspiel_Werbung",
            "title": "Was versteht man unter „Let’s play“?",
            "type": "Wissensfrage",
            "options": [
                "Eine Form von Brettspielen",
                "Es wird das Spielen eines Spiels vorgeführt, indem es gestreamt oder auf Videoportalen hochgeladen wird",
                "Ein Kommando für Dackel, das diese zum Ballspielen auffordern soll.",
                "Das freiwillige Überlassen von Ressourcen im Spiel"
            ],
            "id": 11,
            "correct": 1
        },
        {
            "correct": 0,
            "id": 12,
            "type": "Wissensfrage",
            "media": "media/87edd6b3-1b5b-41ea-a15e-aff2d1e06594-Webung",
            "title": "Wahr oder Falsch? Werbung wird bewusst reizvoll und spannend dargestellt, um Spieler:innen zum Ausprobieren anzuregen. ",
            "options": [
                "Richtig",
                "Falsch"
            ]
        },
        {
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "correct": 0,
            "title": "Das ist im Glücksspielstaatsvertrag 2021 (GlüStV), § 5, Absatz 2 geregelt: „Werbung darf sich nicht an Minderjährige oder vergleichbar gefährdete Zielgruppen richten. Soweit möglich, sind Minderjährige als Empfänger von Werbung auszunehmen.“",
            "media": "media/7cc9efce-d4ed-4983-a03e-84ec59ddaed9-Info",
            "id": 36
        },
        {
            "options": [
                "Richtig",
                "Falsch"
            ],
            "title": "Laut Gesetz darf Werbung für Glücksspiele nicht an Kinder und Jugendliche gerichtet sein. Stimmt das?",
            "correct": 0,
            "type": "Wissensfrage",
            "id": 13,
            "media": "media/abe16dda-9e7a-4d90-8749-8306e6406293-Gesetz"
        },
        {
            "options": [
                "Richtig",
                "Falsch"
            ],
            "type": "Wissensfrage",
            "id": 14,
            "title": "Sind Termine in einer Suchtberatungsstelle kostenfrei?",
            "correct": 0,
            "media": "media/3eda373a-aae1-466d-a358-d4d41042ae44-Beratung"
        },
        {
            "type": "Wissensfrage",
            "options": [
                "Jugendsuchtberatungsstelle",
                "Suchtberatungsstelle",
                "Anonyme Angebote (Chat-Beratung oder Hilfetelefone)"
            ],
            "id": 15,
            "correct": [
                0,
                1,
                2
            ],
            "answerType": "Multiple Choice",
            "media": "media/5a9a2415-2ad9-4598-afda-6887aca4d881-Wegweiser",
            "title": "Wo finde ich Beratung und Hilfe?"
        },
        {
            "answerType": "Multiple Choice",
            "title": "Jemand macht sich Sorgen um dich. Welches Verhalten würdest du dir von der Person wünschen?",
            "correct": [
                0,
                1,
                2,
                3
            ],
            "media": "media/08703797-a560-4954-beae-3470ca9fa7c7-Familie und Mediennutzung",
            "options": [
                "Spricht mich in Ruhe an, um eine gemeinsame Lösung zu finden",
                "Bestrafung: Smartphone-Verbot, Taschengeldkürzung",
                "Geht gemeinsam mit mir zur Beratungsstelle",
                "Spricht mich nicht darauf an"
            ],
            "type": "Erfahrungsfrage",
            "id": 17
        },
        {
            "type": "Wissensfrage",
            "media": "media/f4d7b1f2-215c-4186-80e6-494be1754fcf-Glücksspiel_Würfel2",
            "correct": 1,
            "title": "Wie viele Glücksspielsüchtige schaffen es, nach erfolgreich abgeschlossener Behandlung langfristig spielfrei zu bleiben?",
            "options": [
                "Etwa 40 %",
                "Etwa 60 %",
                "Etwa 80 %",
                "Fast 100 %"
            ],
            "id": 17
        },
        {
            "title": "Seit wann ist die „Computerspielstörung“ eine anerkannte Krankheit?",
            "media": "media/c3dec5b2-bed6-4cb2-bf1a-3e315e306557-Kalender",
            "type": "Wissensfrage",
            "id": 18,
            "correct": 3,
            "options": [
                "1992",
                "2002",
                "2012",
                "2022"
            ]
        },
        {
            "type": "Wissensfrage",
            "title": "Was war das meistverkaufte Game im Jahr 2023?",
            "correct": 2,
            "id": 19,
            "media": "media/87e9f8e9-f0b9-41cc-9bcd-da4d90ba33ba-Gamer",
            "options": [
                "Hogwarts Legacy",
                "Call of Duty: Modern Warfare III",
                "EA SPORTS FC 24"
            ]
        },
        {
            "answerType": "Multiple Choice",
            "title": "„Computerspielstörung“ hat viele Gesichter. Bei welchem Anzeichen würdest du dir Sorgen um eine:n Freund:in machen?",
            "options": [
                "Vernachlässigung der Körperpflege",
                "Abbrechen sozialer Kontakte",
                "Tanzt ständig den Floss-Dance",
                "Leistungsabfall in Schule oder Beruf"
            ],
            "type": "Erfahrungsfrage",
            "media": "media/47eec0e3-876d-4206-aff1-cd4c617a58be-Zocken",
            "correct": [
                0,
                1,
                3,
                2
            ],
            "id": 20
        },
        {
            "options": [
                "Nervosität & Gereiztheit",
                "Augenschmerzen & Rückenprobleme",
                "ADHS & Depressionen"
            ],
            "media": "media/e0115f59-15bf-4ce3-a1f3-ed5bfdb534da-Sorgen / Anspannung PC",
            "correct": 0,
            "type": "Wissensfrage",
            "id": 21,
            "title": "Wobei handelt es sich um typische Entzugssymptome bei exzessiver Mediennutzung?"
        },
        {
            "title": "Wie nennt man den Neurotransmitter (Botenstoff) im Gehirn, der hauptsächlich für die Empfindung von Glück und Zufriedenheit verantwortlich ist?",
            "type": "Wissensfrage",
            "media": "media/873e99cc-7e30-461a-a987-cb28bdc74216-Likes",
            "options": [
                "GABA",
                "Dopamin",
                "Adrenalin"
            ],
            "id": 22,
            "correct": 1
        },
        {
            "id": 23,
            "title": "Es existieren zahlreiche Schutzfaktoren, die eine exzessive Mediennutzung unwahrscheinlicher machen. Welcher der hier aufgeführten Begriffe ist kein Schutzfaktor?",
            "media": "media/15d5be83-48fa-4295-962e-02fa5fba1c41-Ritter / Schutz",
            "correct": 2,
            "options": [
                "Fürsorgliche Familie",
                "Hoher Selbstwert",
                "Exzellentes Aussehen",
                "Interessante Hobbys"
            ],
            "type": "Wissensfrage"
        },
        {
            "correct": 0,
            "id": 24,
            "options": [
                "Wenn es ein Hobby neben anderen Interessen ist",
                "Wenn es jede freie Zeit von mir füllt"
            ],
            "media": "media/ea417a11-48d0-4f66-95d5-b79971679a01-Gaming",
            "type": "Wissensfrage",
            "title": "Wann ist „Zocken“ ein unproblematisches Hobby?"
        },
        {
            "id": 26,
            "title": "Für simuliertes Glücksspiel gelten die gleichen Gesetze wie für „normales“ Glücksspiel?",
            "options": [
                "Richtig",
                "Falsch"
            ],
            "media": "media/1369609f-be1f-4f83-aed9-1a17335bb0ef-Recht",
            "correct": 1,
            "type": "Wissensfrage"
        },
        {
            "correct": 0,
            "title": "Dennoch ist simuliertes Glücksspiel ein wichtiger Aspekt für den Jugendmedienschutz. So heißt es Im Gefährdungsatlas: „von digitalen Spielen mit Elementen des simulierten Glücksspiels kann ebenso wie vom Glücksspiel selbst eine Kindes- und Jugendgefährdung ausgehen – aufgrund der Hinleitung zum störungsbehafteten Spielen bzw. zu einem substanzunabhängigen Suchtverhalten.“ „So zeigt sich in ersten Längsschnittstudien, dass Angebote mit Elementen des simulierten Glücksspiels für Spielteilnehmende im Kindes- und Jugendalter das Risiko erhöhen können, in kurzer Zeit (innerhalb weniger Monate) auf echte Glücksspielangebote zuzugreifen.“  Quelle: Bundeszentrale für Kinder- und Jugendmedienschutz (BzKJ): Gefährdungsatlas (2022). Digitales Aufwachsen. Vom Kind aus denken. Zukunftssicher handeln.",
            "options": [],
            "type": "Folie",
            "id": 27,
            "media": "media/7cc9efce-d4ed-4983-a03e-84ec59ddaed9-Info"
        },
        {
            "title": "Lootboxen gelten in Österreich als illegales Glücksspiel.",
            "id": 28,
            "options": [
                "Richtig",
                "Falsch"
            ],
            "correct": 0,
            "media": "media/abe16dda-9e7a-4d90-8749-8306e6406293-Gesetz",
            "type": "Wissensfrage"
        },
        {
            "title": "Sportwetten im Internet – was ist auf den meisten Wett-Portalen NICHT möglich?",
            "type": "Wissensfrage",
            "id": 29,
            "correct": 2,
            "media": "media/6f6015e1-b689-42d5-9b3c-c392e3ae5ec9-Sportwette",
            "options": [
                "Sich mit einem Klick sperren lassen",
                "Online Casino-Games spielen",
                "Auf Pump spielen"
            ]
        },
        {
            "correct": [
                0,
                1,
                2,
                3
            ],
            "answerType": "Multiple Choice",
            "options": [
                "Ich ziehe das Los selbst.",
                "Der Veranstalter gibt mir das Los.",
                "Meine Mutter sucht mir ein Los aus.",
                "Es ist mir egal."
            ],
            "media": "media/e8c5c1e2-0f5d-421e-bb4e-ebdd6febc5c8-Los",
            "type": "Erfahrungsfrage",
            "title": "Stell dir vor, du nimmst an einer Verlosung teil. Wie möchtest du dein Los bekommen?",
            "id": 30
        },
        {
            "title": "Man kann sich selber oder eine angehörige Person für Glücksspiele sperren lassen.",
            "id": 31,
            "type": "Wissensfrage",
            "media": "media/70851fbe-1b8c-498c-8203-c1ec9697fabd-Glücksspiel_Spielersperre",
            "correct": 0,
            "options": [
                "Richtig",
                "Falsch"
            ]
        },
        {
            "type": "Wissensfrage",
            "id": 32,
            "options": [
                "Richtig",
                "Falsch"
            ],
            "title": "Spielhallen müssen mindestens 500 m von Schulen entfernt sein.",
            "media": "media/3ddd2a13-fcf2-47de-a23a-a5d948c0d18c-Glücksspiel_Spielhalle",
            "correct": 1
        },
        {
            "id": 33,
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "Die Spielhalle soll nicht in räumlicher Nähe zu öffentlichen Schulen und Einrichtungen der Kinder- und Jugendhilfe betrieben werden; dabei soll der Mindestabstand von 350 Metern eingehalten werden.",
            "type": "Folie"
        },
        {
            "title": "Das italienische Parlament hat 2018 beschlossen, dass Geldspielautomaten in Italien folgenden Aufdruck erhalten sollen:",
            "correct": 1,
            "id": 33,
            "media": "media/7f236551-2293-41ce-a7fd-8a6543220292-Vertrag",
            "options": [
                "Viel Vergnügen beim Spielen",
                "Schadet ernsthaft der Gesundheit",
                "Pro Person maximal 10 Spiele",
                "Kein Spiel unter Alkoholeinfluss"
            ],
            "type": "Wissensfrage"
        },
        {
            "type": "Folie",
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "Ihr habt Fragen? Meldet Euch für das Thema Glücksspiel gerne bei kontakt@gluexxit.de und für das Thema Exzessive Mediennutzung bei info@ginko-stiftung.de.",
            "id": 34,
            "media": "media/d35666bb-47eb-4e09-a132-01da8ecd4f35-Infofolie WhatsOn und GLUEXXIT"
        }
    ],
    "description": 'Dieses Quiz wurde in Kooperation der Landesfachstellen Glücksspielsucht und Prävention NRW erstellt. Es vereint die Themenbereiche Glücksspielsuchtprävention und Prävention exzessiver Mediennutzung. Die Fragen des Quiz decken die Themenbereiche Gesundheit, Zahlen und Fakten, Werbung, Schutz, Hilfesystem sowie Medien und Glücksspiel ab.\n\nIm Fokus steht die Schnittmenge beider Arbeitsbereiche: Das sogenannte "simulierte Glücksspiel", z.B. Lootboxen.\n\nStand: Frühjahr 2024\n\nRückfragen:\nLandesfachstelle Glücksspielsucht  - www.gluecksspielsucht-nrw.de / www.gluexxit.de\nLandesfachstelle Prävention NRW - www.ginko-stiftung.de',
    "publicTemplate": true,
}, {
    // to be overwritten
    "organization": "3F8QSNCNpdoCVeROUSuU",
    "created": 1706274988748,
    "creator": "UuS2Q2RiSueVBWDPnAsceJRWcGp2",

    // to stay the same
    "id": "5Jh9gO4MOwL6WddG7aWQ",
    "publicTemplate": true,
    "questions": [
        {
            "type": "Erfahrungsfrage",
            "options": [
                "13 Jahre oder jünger",
                "14 - 17 Jahre ",
                "18 - 21 Jahre",
                "22 Jahre oder älter"
            ],
            "title": "Wie alt bist du?",
            "media": "media/19614687-c622-43d1-98ec-6cd502020847-Fragezeichen",
            "correct": 0,
            "id": 25,
            "fromLibrary": true
        },
        {
            "options": [
                "weiblich",
                "männlich",
                "divers"
            ],
            "correct": 0,
            "type": "Erfahrungsfrage",
            "media": "media/9d2448b4-a505-4719-ae00-aae9b470f15e-Abwägen_Überlegen",
            "title": "Bist du...?",
            "fromLibrary": true,
            "id": 28
        },
        {
            "type": "Erfahrungsfrage",
            "media": "media/2e67789e-9a82-4c2c-8c62-193da31f23e7-Gläser",
            "answerType": "Multiple Choice",
            "id": 26,
            "correct": [
                1,
                0
            ],
            "fromLibrary": true,
            "options": [
                "Ja",
                "Nein"
            ],
            "title": "Hast du Alkohol schon mindestens einmal ausprobiert?"
        },
        {
            "title": "Kennst du Hilfsangebote in deiner Nähe?",
            "media": "media/5a9a2415-2ad9-4598-afda-6887aca4d881-Wegweiser",
            "options": [
                "Ja",
                "Nein"
            ],
            "id": 29,
            "fromLibrary": true,
            "type": "Erfahrungsfrage",
            "correct": 0
        },
        {
            "title": "Was ist Alkohol?",
            "answerType": "Multiple Choice",
            "options": [
                "Bitter und brennend schmeckende Flüssigkeit",
                "Ein Desinfektionsmittel",
                "Zellgift, das fast alle Körperzellen und Organe schädigen kann",
                "Ein typischer Feierabend-Drink"
            ],
            "correct": [
                0,
                2
            ],
            "id": 0,
            "type": "Wissensfrage",
            "media": "media/2e67789e-9a82-4c2c-8c62-193da31f23e7-Gläser"
        },
        {
            "correct": [
                2,
                1
            ],
            "options": [
                "Bekleidungsindustrie",
                "Reinigungsmittel",
                "Kosmetika",
                "Tierfutter"
            ],
            "type": "Wissensfrage",
            "id": 15,
            "answerType": "Multiple Choice",
            "title": "Wo findet Alkohol, außer als Genussmittel, noch weitere Verwendung?",
            "media": "media/c31d369c-e46f-40b6-83dc-ecaa9defe4ef-Bunte Flaschen"
        },
        {
            "options": [
                "Mundschleimhaut",
                "Magen",
                "Dünndarm",
                "Leber"
            ],
            "id": 1,
            "type": "Wissensfrage",
            "media": "media/e12340d1-e6f4-4255-93a9-47b66e1b9168-Körpermodell",
            "correct": 2,
            "title": "Über welches Organ nimmt der Körper den größten Teil des Alkohols in das Blut auf?"
        },
        {
            "type": "Wissensfrage",
            "media": "media/d9c30b88-eeb0-45f8-92bf-32924c7feb69-Kind mit Bierflasche",
            "options": [
                "Berauschend und reaktionshemmend",
                "Anregend und stimmungsaufhellend",
                "Ruft Gleichgewichtsstörungen hervor"
            ],
            "correct": [
                0,
                1,
                2
            ],
            "id": 2,
            "answerType": "Multiple Choice",
            "title": "Wie wirkt Alkohol?"
        },
        {
            "id": 3,
            "correct": 2,
            "options": [
                "Über die Blase",
                "Über den Darm",
                "Über die Leber"
            ],
            "type": "Wissensfrage",
            "title": "Wie verschwindet Alkohol wieder aus dem Körper?",
            "media": "media/ab39e9f7-73cb-48fa-b6bc-d5c746662658-Organe"
        },
        {
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "media": "media/ab39e9f7-73cb-48fa-b6bc-d5c746662658-Organe",
            "id": 4,
            "type": "Folie",
            "title": "Der Abbauprozess braucht viel Zeit: pro Stunde kann die Leber ca. 0,1 Promille abbauen.",
            "correct": 0
        },
        {
            "id": 5,
            "media": "media/661b88ad-34e1-43b0-bde8-84a09474bb66-Alkohol am Arbeitsplatz",
            "type": "Wissensfrage",
            "correct": 0,
            "options": [
                "Richtig",
                "Falsch"
            ],
            "title": "\"Alkohol kann abhängig machen!\" Richtig oder falsch?"
        },
        {
            "correct": 0,
            "title": "Alkohol kann abhängig machen. Körperlich und psychisch. Entzugssymptome sind unter anderem: Unruhegefühle, Zittern der Hände oder man verspürt einen großen Druck Alkohol zu sich zu nehmen.",
            "id": 6,
            "media": "media/661b88ad-34e1-43b0-bde8-84a09474bb66-Alkohol am Arbeitsplatz",
            "type": "Folie",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ]
        },
        {
            "options": [
                "Richtig",
                "Falsch"
            ],
            "type": "Wissensfrage",
            "correct": 1,
            "media": "media/492420e9-1aa1-4c02-851b-e1f5e20446ea-Übergeben",
            "title": "\"Wenn es einem übel wird, sollte man sich übergeben. Danach ist man wieder nüchtern und es geht einem besser!\" Richtig oder falsch?",
            "id": 8
        },
        {
            "type": "Folie",
            "correct": 0,
            "media": "media/492420e9-1aa1-4c02-851b-e1f5e20446ea-Übergeben",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "title": "Das stimmt nicht, denn ein Teil des Alkohols wird sofort nach dem Trinken über die Mundschleimhaut, die Speiseröhre und den Magen ins Blut aufgenommen. Bis man sich übergeben muss, ist der Alkohol bereits im Blut und kann erst über die Leber abgebaut werden.",
            "id": 9
        },
        {
            "answerType": "Multiple Choice",
            "options": [
                "Bei der Person bleiben",
                "Alkoholfreies Getränk anbieten",
                "Die Person erbrechen lassen",
                "Ein Konterbier anbieten"
            ],
            "type": "Wissensfrage",
            "media": "media/838708d3-8b93-4284-b453-584e9bb6f8b8-Erste Hilfe",
            "correct": [
                0,
                1
            ],
            "timeBonus": false,
            "id": 7,
            "title": "Was solltest du in einem Notfall tun?"
        },
        {
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 14,
            "title": "Auch wenn Du Angst vor Ärger oder möglichen Konsequenzen hast: Wenn keine Besserung eintritt oder Du unsicher über den Zustand Deines Freundes oder Deiner Freundin bist, rufe ärztliche Hilfe: Notruf 112",
            "type": "Folie",
            "media": "media/838708d3-8b93-4284-b453-584e9bb6f8b8-Erste Hilfe"
        },
        {
            "id": 10,
            "title": "Was kannst du tun, um \"sicherer\" zu feiern?",
            "options": [
                "Verzichte auf Trinkspiele",
                "Zwischendurch immer mal wieder ein Wasser oder alkoholfreies Getränk",
                "Schnell und viel am Anfang trinken, damit genug Zeit zum Ausnüchtern ist"
            ],
            "type": "Wissensfrage",
            "answerType": "Multiple Choice",
            "correct": [
                0,
                1
            ],
            "media": "media/edb6f76f-1976-41c1-bcbd-0af035a928bd-Feiern"
        },
        {
            "type": "Wissensfrage",
            "id": 11,
            "answerType": "Multiple Choice",
            "title": "Was ist erlaubt?",
            "media": "media/a28c4f21-b9d7-4e2c-ab57-bcbc4d35cb18-Zeichen \"Kein Alkohol\"",
            "correct": [
                0,
                1,
                3
            ],
            "options": [
                "Bier ab 16 Jahre",
                "Wein ab 18 Jahre",
                "Kurze ab 16 Jahre",
                "Sekt ab 16 Jahre"
            ]
        },
        {
            "correct": 0,
            "type": "Folie",
            "id": 12,
            "media": "media/a28c4f21-b9d7-4e2c-ab57-bcbc4d35cb18-Zeichen \"Kein Alkohol\"",
            "title": "Das Jugendschutzgesetz will Kinder und Jugendliche vor entwickelungsschädigenden Einflüssen jeder Art bewahren. Hierzu gehört auch der Umgang mit Alkohol (JuSCHG §9).",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ]
        },
        {
            "type": "Wissensfrage",
            "id": 13,
            "correct": 1,
            "options": [
                "50 - 100 Euro",
                "150 - 250 Euro",
                "400 - 500 Euro"
            ],
            "media": "media/763aaabb-5891-464a-9206-917f77de07d1-Alkohol und Autoschlüssel",
            "title": "Wie hoch ist das Bußgeld, wenn man mehr als 0 Promille aber weniger als 0,5 Promille in der Probezeit hat?"
        },
        {
            "correct": 1,
            "type": "Wissensfrage",
            "options": [
                "Trinken bei Feierlichkeiten, wie einem Geburtstag oder einer Hochzeit.",
                "Trinken ist zum Bestandteil des Alltags geworden und an bestimmte Situationen geknüpft wie z.B. Fußball schauen.",
                "Es wird getrunken, um Spannungen und Stress abzubauen."
            ],
            "media": "media/396eb9da-9678-40a8-b1b9-a14353c97123-Glücksspiel_Fußball",
            "id": 16,
            "title": "Was bedeutet \"Gewohnheitstrinken\"?"
        },
        {
            "title": "Infofolie \"Suchtverlauf\"",
            "media": "media/8dccf5c1-7d1a-4220-8bf2-4c784678f4c3-Suchtverlauf",
            "type": "Folie",
            "correct": 0,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "id": 24
        },
        {
            "id": 17,
            "correct": 0,
            "type": "Wissensfrage",
            "options": [
                "Es kann zu einem Alkoholdelir kommen.",
                "Ihm wird vom Geruch alkoholischer Getränke schlecht.",
                "Er beruhigt seinen Körper mit einer anderen Substanz.",
                "Es kommt zu Reizbarkeit."
            ],
            "media": "media/39259633-0809-4705-bfa6-8819604f177f-EKG",
            "title": "Welche Gefahr besteht, wenn ein Mensch mit Alkoholgebrauchsstörung plötzlich mit dem Trinken aufhört?"
        },
        {
            "media": "media/4f8762c6-155e-4998-989a-dde171320da9-Alkoholgehalt",
            "id": 18,
            "options": [
                "Eine große Flasche Bier",
                "Zwei Gläser Schnaps",
                "Ein Glas Sekt"
            ],
            "type": "Wissensfrage",
            "title": "Welches Getränk enthält am meisten Alkohol?",
            "correct": 0
        },
        {
            "id": 19,
            "media": "media/5beb0e48-1c43-4a01-a8e2-aadae7c45438-Bier am Strand",
            "title": "Wie viele Kilokalorien hat eine Flasche Bier (0,3l)?",
            "correct": 1,
            "options": [
                "Keine Kalorien",
                "125 Kalorien",
                "250 Kalorien"
            ],
            "type": "Wissensfrage"
        },
        {
            "media": "media/c3555004-487b-4a77-a35d-9a3e37286a4d-Süßigkeiten",
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "type": "Folie",
            "id": 22,
            "title": "Eine Flasche Bier enthält 125 kcal. Das ist ungefähr so viel wie 35 Gummibärchen.",
            "correct": 0
        },
        {
            "title": "Wie viele Menschen werden jährlich wegen einer Alkoholgebrauchsstörung ambulant oder stationär in Deutschland behandelt?",
            "type": "Wissensfrage",
            "correct": 1,
            "id": 20,
            "media": "media/a5da259b-23dc-4d1b-9134-ec677a7c61fc-Menschenmenge",
            "options": [
                "600.000",
                "1,5 Millionen",
                "10 Millionen"
            ]
        },
        {
            "title": "Etwa 1,5 Millionen Personen mussten im Jahr 2022 ambulant oder stationär wegen ihrer Alkoholerkrankung behandelt werden. Das ergab eine Auswertung der Barmer Krankenkasse. Betroffen sind vor allem Menschen in der zweiten Lebenshälfte.",
            "type": "Folie",
            "id": 21,
            "options": [
                "Antwortoption 1",
                "Antwortoption 2",
                "Antwortoption 3",
                "Antwortoption 4"
            ],
            "correct": 0,
            "media": "media/a5da259b-23dc-4d1b-9134-ec677a7c61fc-Menschenmenge"
        },
        {
            "answerType": "Multiple Choice",
            "correct": [
                0,
                2
            ],
            "options": [
                "Freunde",
                "Genug Wasser trinken",
                "Ein positives Selbstwertgefühl",
                "Gutes Aussehen"
            ],
            "id": 23,
            "title": "Was ist ein Schutzfaktor vor riskantem Konsum?",
            "type": "Wissensfrage",
            "media": "media/9d2448b4-a505-4719-ae00-aae9b470f15e-Abwägen_Überlegen"
        }
    ],
    "name": "Das Alkohol-Quiz",
    "description": "Dieses Quiz wurde durch die Landesfachstelle Prävention der Suchtkooperation NRW erstellt. Grundlage des Quiz bildet die Informationsbroschüre \"Alkohol - Infos und Tipps für Jugendliche\" der Landeskampagne \"Sucht hat immer eine Geschichte\".\n\nStand: Frühjahr 2024\n\nRückfragen: Landesfachstelle Prävention NRW - www.ginko-stiftung.de"
}]