import Vue from 'vue'
import Vuex from 'vuex'
import { auth, globalSettingsCollection, organizationCollectionForUID } from "../plugins/firebase";
import { quizStore } from "./quiz";
import router from "../router";
import { mediaStore } from "./media";
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import { playStore } from "./play";
import { MENU_OPTIONS } from "../views/Dashboard";

Vue.use(Vuex)

export const MESSAGE_TYPES = {
    INFORMATION: 5000,
    SUCCESS: 3000,
    ERROR: 10000,
}

// a function that logs db errors
export function logDBError(store, message) {
    return function (error) {
        console.error(error);
        store.commit('addMessage', {
            type: MESSAGE_TYPES.ERROR,
            text: message,
            developerDetails: error
        });
    }
}

export const notImplementedMessage = "This feature is not implemented yet.";

export function notImplementedYet() {
    this.$store.commit('addMessage', {
        type: MESSAGE_TYPES.INFORMATION,
        text: notImplementedMessage,
    });
}

export default new Vuex.Store({
    state: {
        user: null,
        initialized: false,
        messages: [], // made up of { text, developerDetails and type }
        settings: {},
        organizations: null,
        selectedDashboardTab: MENU_OPTIONS.QUIZ_TEMPLATES
    },
    mutations: {
        setUser: (state, user) => state.user = user,
        setInitialized: (state, initialized) => state.initialized = initialized,
        addMessage: (state, message) => typeof message === "string" || message instanceof String ? state.messages.push({
            type: MESSAGE_TYPES.SUCCESS,
            text: message
        }) : state.messages.push({ type: MESSAGE_TYPES.SUCCESS, ...message }),
        removeMessage: (state, message) => state.messages.remove(message),
        setSelectedDashboardTab: (state, tab) => state.selectedDashboardTab = tab,
        ...vuexfireMutations
    },
    actions: {
        subscribeFirebaseUser(store) {
            auth.onAuthStateChanged(async user => {

                if (user && !user.isAnonymous) {
                    if (router.currentRoute.name === "Login" || router.currentRoute.name === "Join Quiz")
                        router.push("/dashboard");
                } else if (store.state.user)
                    router.push("/login");

                store.commit("setUser", user);

                if (user && !user.isAnonymous) {
                    await store.dispatch("loadQuizzes");
                    await store.dispatch("loadMediaFiles");
                    await store.dispatch("loadPublicMediaFiles");
                    await store.dispatch("loadPersonalMediaFiles");
                    await store.dispatch("loadOrganizations");
                    await store.dispatch("loadSettings");
                }

                store.commit("setInitialized", true);
            });
        },
        loadSettings: firestoreAction(({ bindFirestoreRef, state }) => {
            return bindFirestoreRef('settings', globalSettingsCollection.doc(state.user.uid));
        }),
        loadOrganizations: firestoreAction(({ bindFirestoreRef, state }) => {
            return bindFirestoreRef('organizations', organizationCollectionForUID(state.user.uid));
        }),

    },
    modules: {
        quiz: quizStore,
        media: mediaStore,
        play: playStore
    }
})