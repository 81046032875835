<template>
  <popup-container style="z-index: 100">
    <div class="card max-w-2xl overflow-auto">
      <div class="card-header">
        <p class="card-title h4">Frage aus Fragenbibliothek hinzufügen</p>
      </div>
      <div class="card-body">

        Fragen aus der Fragenbilbiothek werden von der Landesfachstelle Präventation der Suchtkooperation NRW formuliert
        und ausgewertet. Aus diesem Grund können die Fragen inhaltlich nicht angepasst werden, die Bearbeitung der
        Mediendateien ist jedoch möglich. Bei Fragen kontaktieren Sie bitte Lea Würzinger von der Ginko Stiftung für
        Prävention unter <a href="mailto:l.wuerzinger@ginko-stiftung.de"
                            target="_blank">l.wuerzinger@ginko-stiftung.de</a>

        <div class="grid grid-cols-2 mt-6">
          <question-card v-for="(question, id) in questions" :allow-edit="false" :question="question" :question-i-d="id"
                       :quiz=" { questions }" @click="$emit('success', question)"/>
        </div>

      </div>
      <div class="card-footer">
        <button class="btn" @click="$emit('failure')">Abbrechen</button>
      </div>
    </div>
  </popup-container>
</template>
<script>
import PopupContainer from "../gui-components/PopupContainer.vue";
import QuestionCard from "../edit-quiz/QuestionCard.vue";

export default {
  components: { QuestionCard, PopupContainer },
  props: ["questions"]
}
</script>